import styled$1, { css } from 'styled-components';
import { getProperty, space, colorSet, mq, color } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledJobTeaser = styled$1.div`
  width: 100%;
  position: relative;
  ${getTransition}
  ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 5'
})};

  &:not(:first-child) {
    margin-top: ${space(20)};
  }

  &:nth-child(4n + 1) {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
  }

  &:nth-child(even) {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '8 / span 5'
})};
  }

  .jobTeaser__link {
    position: relative;
    padding: ${space(10)} ${space(5)};
    color: ${colorSet('colorSecondary')};
    display: flex;

    ${mq('m')} {
      min-height: ${space(116)};
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: ${colorSet('accent')};
      width: calc(100% + ${space(6)});

      ${mq('m')} {
        width: 100%;
      }
    }
  }

  &:nth-child(odd) .jobTeaser__link:before  {
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .jobTeaser__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .jobTeaser__link-text {
    position: relative;
    display: inline-block;
    align-self: flex-start;
    margin-top: auto;
    text-transform: uppercase;
    letter-spacing: ${space(0.5)};
    transition: all 0.1s ease-in-out;
    padding-bottom: ${space(1)};

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: ${colorSet('colorSecondary')};
      transition: transform 0.1s ease-in-out;
    }
  }

  .jobTeaser__locations {
    margin: ${space(3)} 0 ${space(4)} 0;
  }

  &:hover .jobTeaser__link-text:after {
    transform: translateY(${space(-0.5)});
  }

  ${({
  isExternal
}) => isExternal && css([".jobTeaser__link:before{background:", ";}"], colorSet('backgroundSecondary'))}

  ${({
  isFormTeaser
}) => isFormTeaser && css(["", ";&:nth-child(even),&:nth-child(4n + 1){", ";}.jobTeaser__formTeaser{", ";}.jobTeaser__wrapper,.jobTeaser__form{background:", ";padding:", " ", ";}.jobTeaser__wrapper{", ";h2{color:", ";}&:after{", "{content:'';position:absolute;top:100%;left:0;width:100%;height:250px;background:", ";}}}.jobTeaser__form{", ";", ";", "{padding-right:0;padding-left:0;}> div{", ";}form{display:flex;flex-wrap:wrap;justify-content:space-between;> div{width:calc(50% - 15px);&:nth-last-child(-n+6){width:100%;}}}.select__wrapper{display:block;}a,button,.checkbox__custom-checkbox{color:", ";border-color:", ";svg{fill:", ";}}button:hover{background:", ";}.select__custom-icon svg{fill:", ";}"], getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
}), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
}), getGrid({
  default: 6,
  m: 12,
  l: 12
}, false), color('highlight04'), space(10), space(5), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 7',
  l: '1 / span 5'
}), colorSet('backgroundSecondary'), mq('m'), color('highlight04'), getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 10'
}), getGrid({
  default: 6,
  m: 10
}, false), mq('l'), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10',
  l: '2 / span 8'
}), colorSet('backgroundSecondary'), colorSet('backgroundSecondary'), colorSet('backgroundSecondary'), colorSet('backgroundSecondary'), colorSet('backgroundSecondary'))}
`;

export default StyledJobTeaser;
