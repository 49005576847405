import React, { FC } from 'react';

interface RouterObject {
  link?: string | JSX.Element;
}

interface RouterProviderProps {
  router: RouterObject;
}

const defaultRouter = {
  link: 'a'
};

export const RouterContext = React.createContext<RouterObject>(defaultRouter);

const RouterProvider: FC<RouterProviderProps> = ({ children, router }) => (
  <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  );


export const RouterConsumer = RouterContext.Consumer;

export default RouterProvider;
