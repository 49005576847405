import { useEffect } from 'react';

const useWindowEvent = (
  event: string,
  callback: (event: any) => void,
  returnCallback?: () => void
) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(event, callback);
    }
    return () => {
      if (returnCallback) returnCallback();
      if (typeof window !== 'undefined') {
        window.removeEventListener(event, callback);
      }
    };
  }, [event, callback]);
};

export default useWindowEvent;
