import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import useReveal from '../../hooks/useReveal';
import useIntersect from '../../hooks/useIntersect';

export interface VideoLoopProps {
  src: string;
  loop?: boolean;
  autoPlay?: boolean;
  className?: string;
}

const StyledVideoLoop = styled.video`
  display: block;

  &.lazyload {
    opacity: 0;
    transition: opacity 400ms;
  }

  &.lazyloaded {
    transition: opacity 400ms;
    opacity: 1;
  }
`;

const VideoLoop: FC<VideoLoopProps> = ({ src, loop = true, autoPlay = false, className = '' }) => {
  const [ref, isVisible] = useReveal();
  const [viewportRef, entry] = useIntersect({});

  useEffect(() => {
    const isIntersecting: boolean = entry?.isIntersecting || false;
    if (!entry.target) return;
    if (isIntersecting) {
      entry.target.play();
    } else {
      entry.target.pause();
    }
  }, [entry]);

  return autoPlay ? (
    <StyledVideoLoop
      ref={ref}
      className={`${isVisible ? 'lazyloaded' : 'lazyload'} ${className}`}
      src={isVisible ? src : undefined}
      data-src={!isVisible ? src : undefined}
      autoPlay
      muted
      loop={loop}
      playsInline
    />
  ) : (
    <StyledVideoLoop
      ref={el => {
        ref(el);
        viewportRef(el);
      }}
      className={`${isVisible ? 'lazyloaded' : 'lazyload'} ${className}`}
      src={isVisible ? src : undefined}
      data-src={!isVisible ? src : undefined}
      muted
      autoPlay
      loop={loop}
      playsInline
    />
  );
};

export default VideoLoop;
