import styled from 'styled-components';

interface AspectRatioProps {
  ratio: string;
}

const getRatio = ({ ratio }: AspectRatioProps) => {
  const [width, height] = ratio.split(':');
  return `${height}/${width}`;
};

const AspectRatio = styled.div<AspectRatioProps>`
  height: 0;
  padding-bottom: calc(${getRatio} * 100%);
  position: relative;
  box-sizing: border-box;

  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
`;

AspectRatio.defaultProps = {
  ratio: '16:9'
};

export default AspectRatio;
