import { modularScale } from './utility';
/**
 * Transform px value to rem value.
 * @param {number} px - PX value as a integer. e.g. 12
 * @returns {string} - PX value in REM
 * @example ${px2rem(25)}
 */
export const px2rem = (px: number): string => {
  if (typeof px !== 'number') {
    throw new Error(`${px} is not an integer`);
  }

  return `${px / 16}rem`;
};

/**
 * Returns modular spacing
 * @param  {string} steps - Modular steps for spacing. (Default: 0)
 * @param  {boolean} usePx - By default "rem" values will be returned.
 * @returns {string} Resulting modular spacing
 * @example padding: ${modularSpace(3)};
 */
export const modularSpace = (steps: number, usePx?: boolean) => props => {
  if (!props.theme) {
    throw new Error('No theme object provided');
  }
  const { spacing } = props.theme;

  if (typeof spacing.scale === 'undefined') {
    throw new Error('Spacing base and ratio is not set');
  }

  const space = parseFloat(modularScale(steps, spacing.scale.base, spacing.scale.ratio));

  return usePx ? `${space}px` : px2rem(space);
};

/**
 * Returns spacing
 * @param  {string} multiplier - Multiplier for base space. Default: 0.
 * @param  {boolean} usePx - By default "rem" values will be returned.
 * @returns {string} Resulting spacing
 * @example padding: ${forkSpace(3)};
 */
export const space = (multiplier: number, usePx?: boolean) => props => {
  if (!props.theme) {
    throw new Error('No theme object provided');
  }
  const { spacing } = props.theme;

  if (typeof spacing.base === 'undefined') {
    throw new Error('theme.spacing.base is not defined');
  }

  const spacingValue = parseFloat(spacing.base) * multiplier;

  return usePx ? `${spacingValue}px` : px2rem(spacingValue);
};
