import styled$1 from 'styled-components';
import { space, colorSet, zIndex, getProperty, mq } from '@ccg/core';
import Grid$1, { getGrid } from '../Grid/Grid.js';

const StyledTagTeaser = styled$1(Grid$1)`
  position: relative;
  padding: ${space(12)} 0 ${space(20)};
  background: ${colorSet('background')};
  z-index: ${zIndex('middle')};

  .tagTeaser__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    color: ${colorSet('accent')};
    display: flex;
    align-items: flex-end;
    padding-bottom: ${space(5)};

    h2 {
      max-width: 100%;
      margin-right: ${space(5)};
      flex-shrink: 0;
    }

    .tagTeaser__line {
      position: relative;
      width: 100%;
      max-width: 170px;
      height: 2px;
      background: ${colorSet('accent')};
      margin-bottom: 8px;
      transition: width 0.2s ease-in-out;
    }
  }

  .tagTeaser__container {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${getGrid({
  default: 6,
  m: 12
}, false)};
    grid-row-gap: ${space(25)};
  }

  .tagTeaser__teaser {
    ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 5'
})};

    &:nth-child(even) {
      ${mq('m')} {
        grid-column-start: 7;
      }
    }
  }

  .tagTeaser__tags {
    border-top: 2px solid ${colorSet('accent')};
    margin-top: ${space(2)};
    padding-top: ${space(10)};
  }
`;

export default StyledTagTeaser;
