import styled$1, { css } from 'styled-components';

const getDirectionCSS = props => {
  if (props.direction === 'left') {
    return css(["transform:rotate(2deg) scale(1.1);clip-path:inset(0 0 0 100%);"]);
  }

  return css(["transform:rotate(-2deg) scale(1.1);clip-path:inset(0 100% 0 0);"]);
};

const StyledRevealBox = styled$1.div`
  position: relative;
  overflow: hidden;
  opacity: 0;
  ${getDirectionCSS}
  transition: all 1.2s cubic-bezier(0.5, 0, 0.57, 1), transform 1.1s cubic-bezier(0.5, 0, 0.57, 1);

  ${({
  isVisible
}) => isVisible && css(["opacity:1;clip-path:inset(0 0 0 0);transform:rotate(0deg) scale(1);"])};
`;

export default StyledRevealBox;
