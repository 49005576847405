import styled from 'styled-components';
import { hideVisually } from '../../styles/utility';

const StyledInput = styled.div`
  label,
  input {
    /* hide visually */
    ${hideVisually}
  }
`;

export default StyledInput;
