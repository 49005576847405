import styled$1 from 'styled-components';
import { space, colorSet, mq } from '@ccg/core';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';
import Grid$1 from '../Grid/Grid.js';

const StyledTeamList = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  padding-top: ${space(10)};
  padding-bottom: ${space(10)};
  background: ${colorSet('background')};

  .teamList__headline {
    grid-column: 1 / span 6;
    color: ${colorSet('accent')};

    ${mq('m')} {
      grid-column: 1 / span 8;
    }

    ${mq('l')} {
      grid-column: 1 / span 5;
    }
  }

  .teamList__copy {
    ${getTextColumns};
    margin-top: ${space(6)};

    ${mq('l')} {
      margin-top: 0;
    }
  }

  .teamList__swipe-indicator {
    ${getTextColumns};
    position: relative;
    margin: ${space(6)} 0 0;

    ${mq('m')} {
      margin: ${space(12)} 0;
    }

    &:before,
    &:after {
      content: '';
      position: relative;
      display: inline-block;
      width: 40px;
      height: 1px;
      background: ${colorSet('accent')};
      vertical-align: top;
    }

    svg {
      fill: ${colorSet('accent')};
      margin: 0 ${space(5)};
      transform: translate(-4px, -6px);
      animation: swipe 800ms ease-in-out infinite alternate;
    }

    @media (min-width: 1440px) {
      display: none;
    }
  }

  .teamList__circle {
    position: relative;
    grid-column: 1 / span 12;
    grid-row: 4;
    align-self: start;
    display: flex;

    ${mq('l')} {
      grid-column: ${({
  isRightAligned
}) => isRightAligned ? '7 / span 6' : '1 / span 6'};
    }

    &:not(:first-child) {
      ${mq('l', 'max')} {
        margin-top: ${space(6)};
      }
    }

    &:before {
      content: '';
      position: relative;
      margin-left: ${({
  isRightAligned
}) => isRightAligned && 'auto'};
      width: 80%;
      height: 0;
      padding-bottom: 80%;
      border-radius: 100%;
      background: ${colorSet('backgroundSecondary')};

      ${mq('m')} {
        width: 60%;
        padding-bottom: 60%;
      }

      ${mq('l')} {
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }

  .teamList__link {
    position: relative;
    grid-column: 1 / span 12;
    grid-row: 5;
    margin-top: ${space(10)};
    margin-left: auto;

    a {
      > div {
        display: inline-block;
        transition: transform 200ms;
      }

      svg {
        transform: translateY(2px);
      }

      &:hover {
        > div {
          margin-left: 0;
          transform: translateX(2px);
        }
      }
    }
  }

  .teamList__members {
    position: relative;
    grid-column: 1 / span 6;
    grid-row: 4;
    align-self: center;
    margin-top: ${space(16)};
    overflow-y: hidden;
    overflow-x: auto;
    margin-right: ${space(-5)};
    margin-left: ${space(-5)};

    ${mq('m')} {
      grid-column: 1 / span 12;
      margin-right: ${space(-13)};
      margin-left: ${space(-13)};
    }

    ${mq('l')} {
      margin-top: 0;
      margin-right: ${space(-16)};
      margin-left: ${space(-16)};
    }
  }

  .teamList__members-inner {
    position: relative;
    width: 1209px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .teamList__member-container {
    position: relative;
    width: 33.333%;
    padding: ${space(8)} ${space(17)};
    max-width: 302px;
  }

  .teamList__member {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    will-change: transform;

    .teamList__member-background {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        background: ${colorSet('backgroundSecondary')};
        transition: width 400ms ease-in-out, border 200ms linear 400ms;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .teamList__member-info {
    position: absolute;
    z-index: 1;
    top: 30px;
    width: calc(100% + ${space(17)});
    padding-left: 30px;
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  .teamList__member-name {
    color: ${colorSet('accent')};
  }

  .teamList__member-title {
    color: ${colorSet('background')};
    max-width: 135px;
  }

  .animate-0 {
    animation: 12s linear infinite float-0;
  }

  .animate-1 {
    animation: 8s linear infinite float-1;
  }

  .animate-2 {
    animation: 12s linear infinite float-2;
  }

  .animate-3 {
    animation: 8s linear infinite float-3;
  }

  .teamList__member:active,
  .teamList__member:focus,
  .teamList__member:hover {
    animation-play-state: paused;

    .teamList__member-background {
      border: 2px solid ${colorSet('background')};

      &:after {
        width: 100%;
      }
    }

    .teamList__member-info {
      opacity: 1;
      transition-delay: 400ms;
    }
  }

  @keyframes float-0 {
    from {
      transform: rotate(-0.001deg) translate3d(26px, 20px, 0) rotate(-0.001deg);
    }

    to {
      transform: rotate(360.001deg) translate3d(26px, 20px, 0) rotate(-360.001deg);
    }
  }

  @keyframes float-1 {
    from {
      transform: rotate(360.001deg) translate3d(24px, -5px, 20px) rotate(-360.001deg);
    }

    to {
      transform: rotate(-0.001deg) translate3d(24px, -5px, 20px) rotate(-0.001deg);
    }
  }

  @keyframes float-2 {
    from {
      transform: rotate(-0.001deg) translate3d(28px, -10px, 15px) rotate(-0.001deg);
    }

    to {
      transform: rotate(360.001deg) translate3d(28px, -10px, 15px) rotate(-360.001deg);
    }
  }

  @keyframes float-3 {
    from {
      transform: rotate(360.001deg) translate3d(32px, 3px, -15px) rotate(-360.001deg);
    }

    to {
      transform: rotate(-0.001deg) translate3d(32px, 3px, -15px) rotate(-0.001deg);
    }
  }

  @keyframes swipe {
    from {
      transform: translate(-4px, -6px);
    }

    to {
      transform: translate(4px, -6px);
    }
  }
`;

export default StyledTeamList;
