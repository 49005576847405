import styled$1, { css } from 'styled-components';
import { colorSet, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledTags = styled$1.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20px;

  .tags__tag {
    ${getTypography.small}
    display: inline-block;
    color: ${colorSet('color')};
    background-color: ${colorSet('neutral')};
    padding: ${space(1)} ${space(3)};
    margin-bottom: ${space(2)};
    margin-right: ${space(2)};
  }

  a:active,
  a:focus,
  a:hover {
    color: ${colorSet('accent')};
  }

  ${({
  accentColor
}) => accentColor && css(["&:before{background:", ";}"], accentColor)}

  ${({
  horizontal
}) => horizontal ? css(["flex-direction:row;flex-wrap:wrap;&:before{position:absolute;left:0;top:2px;}"]) : css([".tags__tag{align-self:flex-start;}"])}
`;

export default StyledTags;
