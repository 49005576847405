import styled$1 from 'styled-components';
import { space } from '@ccg/core';
import { getOuterMargins } from '../Grid/Grid.js';

const StyledSocialFeed = styled$1.div`
  ${getOuterMargins};
  padding: ${space(12)} 0 ${space(20)};
`;

export default StyledSocialFeed;
