/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

import Cookies from 'js-cookie';

interface TextProps {
  content: string;
  className?: string;
}

const Text: FC<TextProps> = React.forwardRef<HTMLDivElement, TextProps>(
  ({ content, className, ...props }, ref) => {
    const handleMixedCopyClick = e => {
      // handle opt out/in button click
      if (e.target.hash === '#opt-out-tracking') {
        e.preventDefault();

        // also see cookie layer
        Cookies.set('acceptCookies', 'reject', { expires: 14 });
        alert('Done!');
      }
      if (e.target.hash === '#opt-in-tracking') {
        e.preventDefault();

        // also see cookie layer
        Cookies.set('acceptCookies', 'accept', { expires: 14 });
        alert('Done!');
      }
      if (e.target.hash === '#external-content-opt-out-tracking') {
        e.preventDefault();

        // also see social embed
        Cookies.set('acceptExternalContentCookies', 'reject', { expires: 14 });
        alert('Done!');
      }
      if (e.target.hash === '#external-content-opt-in-tracking') {
        e.preventDefault();

        // also see social embed
        Cookies.set('acceptExternalContentCookies', 'accept', { expires: 14 });
        alert('Done!');
      }
    };

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
        className={className}
        onClick={handleMixedCopyClick}
        ref={ref}
        {...props}
      />
    );
  }
);

Text.displayName = 'Text';

export default Text;
