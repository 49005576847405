export const buildSrcSet = (srcset: { [key: string]: string }) => {
  if (!srcset) return '';

  return Object.keys(srcset)
    .map(key => `${srcset[key]} ${key}`)
    .join(',');
};

export interface ImageObject {
  alt?: string;
  role?: string;
  src?: string;
  'data-src'?: string;
  'data-srcset'?: string;
  'data-sizes'?: string;
  sizes?: string;
  srcSet?: string;
  loading?: 'lazy' | 'eager';
  imageClass?: string;
}

export interface ImageReturnObject {
  image: ImageObject;
  imageClass: string;
}

export const buildImageObject = (obj: ImageObject, lazy?: boolean): ImageReturnObject => {
  let image = obj;
  let imageClass = '';

  if (lazy) {
    image = {
      ...image,
      src: undefined,
      sizes: undefined,
      srcSet: undefined,
      'data-srcset': obj.srcSet,
      'data-sizes': obj.sizes,
      'data-src': obj.src
    };
    imageClass = 'lazyload';
  }

  return { image, imageClass };
};
