import { useState } from 'react';

interface GalleryObject {
  index: number;
  isOverview: boolean;
  handleNext: () => void;
  handlePrev: () => void;
  handleChange: (i: number) => void;
  toggleOverview: (bool: boolean) => void;
}

const useGallery = (images: any[], initialOpen?: boolean): GalleryObject => {
  const [index, setIndex] = useState<number>(0);
  const [isOverview, setIsOverview] = useState<boolean>(initialOpen || false);

  const handleNext = () => {
    if (isOverview) setIsOverview(false);
    setIndex(prev => (prev + 1 > images.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    if (isOverview) setIsOverview(false);
    setIndex(prev => (prev - 1 < 0 ? images.length - 1 : prev - 1));
  };

  const handleChange = (i: number) => {
    if (isOverview) setIsOverview(false);

    if (index > images.length - 1) {
      setIndex(images.length - 1);
    } else if (i < 0) {
      setIndex(0);
    } else {
      setIndex(i);
    }
  };

  const toggleOverview = () => {
    setIsOverview(prev => !prev);
  };

  return {
    index,
    isOverview,
    handleNext,
    handlePrev,
    handleChange,
    toggleOverview
  };
};

export default useGallery;
