/* eslint-disable import/prefer-default-export */
import adaptiveModularScale from 'adaptive-modular-scale';

/**
 * Returns font family defined inside theme.fonts object
 * @param  {string} key - Key of font family defined inside theme.fonts object. Default: "default".
 * @returns {string} Resulting font family
 * @example ${fontFamily("accent")};
 */
export const fontFamily = (key = 'default') => props => {
  if (!props.theme.fonts) {
    throw Error('You need to specify a theme.fonts object.');
  }

  if (!props.theme.fonts[key]) {
    throw Error(`theme.fonts.${key} not specified inside theme object.`);
  }

  return `font-family: ${props.theme.fonts[key].family};`;
};

/**
 * Returns adaptive modular font sizes
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {array} corrections - Corrections array. Default: null.
 * @returns {string} Resulting adaptive modular font sizes
 * @example ${fontSize(3, [-1, 2])};
 */
export const fontSize = (step: number, corrections?: [number, number]) => ({ theme }) => {
  const { typography } = theme;
  const {
    scale: { ratio, base, width, breakpoints }
  } = typography;

  const corr = corrections ? { [step.toString()]: corrections } : {};

  return adaptiveModularScale(step, {
    ratio,
    base,
    width,
    breakpoints: parseFloat(breakpoints),
    corrections: corr
  })({ theme });
};

/**
 * Returns font declarations
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {string} familyKey - Key of font family inside theme.fonts object. Default: "default".
 * @returns {string} Resulting font declarations
 * @example ${font(3, "accent")};
 */
export const font = (step: number, familyKey?: string) => ({ theme }) => {
  const {
    typography: {
      scale: { corrections }
    }
  } = theme;

  const key = familyKey || 'default';

  const corr = corrections && corrections[key] ? corrections[key][step] : null;

  const family = fontFamily(familyKey)({ theme });
  const fontSizes = fontSize(step, corr)({ theme });

  return `${family}\n${fontSizes}`;
};
