import React, { ReactNode, FC } from 'react';

export interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  headline?: string;
  error?: boolean;
  errorMessage?: string;
  icon: ReactNode;
  id: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  checked: boolean;
  value: string;
}

const Checkbox: FC<CheckboxProps> = ({
  className,
  disabled,
  headline,
  error,
  errorMessage,
  icon,
  id,
  label,
  onChange,
  required,
  checked,
  value
}) => {
  return (
    <div className={className}>
      {headline && <span className="checkbox__headline">{headline}</span>}
      <label htmlFor={id}>
        <input
          id={id}
          name={id}
          type="checkbox"
          onChange={onChange}
          aria-invalid={error}
          disabled={disabled}
          checked={checked}
          value={value}
        />
        <div className="checkbox__custom-checkbox">
          <div className="checkbox__custom-checkbox-inner">{icon}</div>
        </div>
        <span className="checkbox__label">
          {label}
          {!required && <span className="checkbox__optional">optional</span>}
        </span>
      </label>
      {error && errorMessage && <span className="checkbox__error">{errorMessage}</span>}
    </div>
  );
};

export default Checkbox;
