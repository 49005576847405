const NextI18Next = require('next-i18next').default;

const getLanguageFromHostname = host => {
  const mapping = {
    // Qatar
    'craft-ccg-qt-en.localhost:5000': 'en',
    'ccg-qt-en.dev.4rk.de': 'en',
    'ccg-qt-en.fischerappelt.corpex-kunden.de': 'en',
    'qatar.fischerappelt.com': 'en',
    // Middleeast
    'craft-ccg-middleeast-en.localhost:5000': 'en',
    'ccg-middleeast-en.dev.4rk.de': 'en',
    'middleeast.fischerappelt.com': 'en',
    // fA
    'craft-ccg-fa-en.localhost:5000': 'en',
    'ccg-fa-en.dev.4rk.de': 'en',
    'ccg-fa-en.fischerappelt.corpex-kunden.de': 'en',
    'www.fischerappelt.com': 'en'
  };

  return mapping[host] || 'de';
};

const nextI18NextConfig = {
  defaultLanguage: 'de',
  otherLanguages: ['en'],
  languages: ['en', 'de'],
  localeSubpaths: {
    en: 'en'
  },
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  localePath:
    typeof window === 'undefined'
      ? `public/${process.env.ACTIVE_SITE}/locales`
      : `${process.env.ACTIVE_SITE}/locales`
};

if (process.env.ACTIVE_SITE === 'puk') {
  nextI18NextConfig.localeSubpaths = {
    de: 'de',
    en: 'en'
  };
}

if (
  process.env.ACTIVE_SITE === 'fischerappelt' ||
  process.env.ACTIVE_SITE === 'qatar' ||
  process.env.ACTIVE_SITE === 'middleeast'
) {
  nextI18NextConfig.localeSubpaths = {};

  const languageByDomain = {
    name: 'domain',
    lookup: req => {
      let language = 'de';

      if (typeof window !== 'undefined') {
        language = getLanguageFromHostname(window.location.hostname);
      } else {
        language = getLanguageFromHostname(req.get('host'));
      }

      return language;
    }
  };

  nextI18NextConfig.serverLanguageDetection = true;
  nextI18NextConfig.customDetectors = [languageByDomain];
  nextI18NextConfig.detection = {
    order: ['domain']
  };
}

module.exports = new NextI18Next(nextI18NextConfig);
