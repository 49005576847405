import styled$1, { css } from 'styled-components';
import { Radio as Radio$1, hideVisually, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { space } from '../../styles/spacing.js';

const Radio = styled$1(Radio$1)`
  input {
    ${hideVisually}
  }

  label {
    display: flex;
    align-items: center;
    margin: ${space(2)} 0;
    cursor: pointer;
  }

  .radio__options {
    display: flex;
    flex-wrap: wrap;

    ${mq('m')} {
      flex-direction: ${props => props.alignment === 'horizontal' ? 'row' : 'column'};
    }
  }

  .radio__item {
    flex-shrink: 0;
    margin-right: ${space(4)};

    label {
      display: flex;
      flex-direction: ${props => props.variant === 'right' ? 'row-reverse' : 'row'};
      justify-content: ${props => props.variant === 'right' ? 'flex-end' : 'flex-start'};
    }
  }

  .radio__custom-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid ${colorSet('accent')};

    ${props => props.variant === 'right' ? css(["margin-left:", ";"], space(2)) : css(["margin-right:", ";"], space(2))}
  }

  .radio__custom-icon-inner {
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${colorSet('accent')};
  }

  .radio__label {
    ${getTypography.copy}
    display: block;
  }

  .radio__optional {
    margin-left: ${space(2)};
    ${getTypography.small}
  }

  .radio__error {
    display: block;
    color: ${colorSet('accent')};
    ${getTypography.small}
  }

  input:checked + label .radio__custom-icon-inner {
    display: block;
  }

  input[disabled] + label {
    opacity: 0.5;
    pointer-events: none;

    .radio__custom-icon {
      border: 2px solid ${colorSet('color')};
    }
  }
`;

export default Radio;
