import React, { FC } from 'react';

export interface InputProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  description?: string;
  id: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
  pattern?: string;
  autocomplete?: string;
  placeholder?: string;
  type?: string;
}

const Input: FC<InputProps> = ({
  autocomplete,
  className,
  disabled,
  error,
  errorMessage,
  description,
  id,
  label,
  onChange,
  onFocus,
  pattern,
  placeholder,
  required,
  type,
  value
}) => {
  return (
    <div className={className}>
      <label htmlFor={id}>
        <span className="input__labelText">{label}</span>
        {!required && <span className="input__optional">optional</span>}
        {description && <span className="input__description">{description}</span>}
      </label>

      <div className="input__wrapper">
        <input
          id={id}
          name={id}
          type={type || 'text'}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          aria-invalid={error}
          aria-required={required}
          disabled={disabled}
          pattern={pattern}
          autoComplete={autocomplete}
          value={value}
        />
      </div>
      {error && errorMessage && <span className="input__error">{errorMessage}</span>}
    </div>
  );
};

export default Input;
