import styled$1, { css } from 'styled-components';
import { space, color, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';
import { getGrid } from '../Grid/Grid.js';

const StyledForm = styled$1.div`
  position: relative;


  ${({
  hasGrid
}) => hasGrid && css(["", " .form__actions,.form__form{", "}"], getGrid(), getTextColumns)};

  .form__actions {
    display: flex;
    flex-wrap: wrap;
  }

  .form__form {
    margin-top: ${space(10)};
    margin-bottom: ${space(10)};
  }

  .form__toggle,
  .form__link {
    margin: ${space(10)} ${space(4)} 0 0;
  }

  .form__link {
    line-height: 1;
  }

  .form__input {
    margin-bottom: ${space(5)};
  }

  .form__upload {
    display: block;
    width: 100%;
    background: ${color('neutral')};
    outline: none;
    padding: ${space(3)} ${space(3)} ${space(2)};
    border: none;
  }

  .form__messages {
    color: ${colorSet('accent')};
  }

  .form__error-message {
    margin-bottom: ${space(1)};
  }

  .form__submit {
    margin-top: ${space(8)};
    margin-left: 0;
    margin-right: 0;
  }

  .form__text {
    ${getTypography.small}
  }

  .form__recaptcha .input__error {
    color: ${colorSet('accent')};
    ${getTypography.small}
    margin-bottom: ${space(1)};
  }
`;

export default StyledForm;
