export const getYoutubeId = (string: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = string.match(regExp);
  return match && match[7].length === 11 ? match[7] : string;
};

export const getVimeoId = (string: string): string => {
  const regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;
  const match = string.match(regExp);
  return match && match[4] ? match[4] : string;
};
