/* eslint-disable import/prefer-default-export */

import { px2rem } from './spacing';

/**
 * Simple CSS MediaQuery. Expects a breakpoint and min or max string (default min).
 * @param {string} mediaQuery - Identifier for the breakpoint in the configuration file
 * @param {string} minMax - Default: min. String for the media-query type
 * @returns {string} Media query definition
 * @example ${mq('m', 'max')} { css styles go here };
 */
export const mq = (mediaQuery: string, minMax?: 'max' | 'min') => ({ theme }: any): string => {
  const defaultMediaQueries = {
    s: 375,
    m: 768,
    l: 1440
  };

  const mediaQueries = theme.breakpoints || defaultMediaQueries;

  if (typeof mediaQuery !== 'number' && typeof mediaQuery !== 'string') {
    throw new Error('mediaQuery must be a string or number');
  }

  if (!theme.breakpoints || !Object.prototype.hasOwnProperty.call(theme.breakpoints, mediaQuery)) {
    console.warn('No media queries set in design tokens. Fallback to default breakpoints.');
  }

  const width = parseFloat(mediaQueries[mediaQuery]);
  const value = minMax === 'max' ? width - 1 : width;

  return `
    @media (${minMax || 'min'}-width: ${px2rem(value)}) 
  `;
};

interface PropertyObject {
  [key: string]: string | number;
}

type PropertyTransform = (value: string | number) => string | number;

export const getProperty = (
  property: string,
  propertyObject: PropertyObject,
  transform?: PropertyTransform
) => ({ theme }): string => {
  let columns = '';

  Object.keys(propertyObject).forEach(key => {
    const item = propertyObject[key];
    const value = transform ? transform(item) : item;

    const string =
      key === 'default'
        ? `${property}: ${value};`
        : `${mq(key)({ theme })} { ${property}: ${value};}`;

    columns += string;
  });

  return columns;
};
