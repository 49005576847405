import React, { useContext, FC } from 'react';

import { RouterContext } from '../../contexts/RouterProvider/RouterProvider';

export interface LinkProps {
  href: string;
  target?: '_blank' | '_self';
  asPath: string;
  component: any;
}
const Link: FC<LinkProps> = ({ children, href, target, asPath, component, ...props }) => {
  const router = useContext(RouterContext);
  const Component = component || router.link || 'a';

  return (
    <Component
      href={href}
      target={target}
      rel={target && target === '_blank' ? 'noopener noreferrer' : undefined}
      asPath={asPath}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Link;
