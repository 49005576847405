import React, { FC } from 'react';
import styled from 'styled-components';

import AspectRatio from '../AspectRatio/AspectRatio';
import { buildImageObject, buildSrcSet } from './helper';

interface ImageProps {
  alt?: string;
  src?: string;
  url?: string;
  /**
   * srcset object, e.g. { 300vw: "path/to/image", 400vw: "path/to/image" }
   */
  srcset?: {
    [key: string]: string;
  };
  sizes?: string;
  /**
   * Defines if image should lazy load
   */
  lazy?: boolean;
  /**
   * Add ratio (e.g. 16:9) to avoid unintended jumping while image loading
   */
  ratio?: string;
  className?: string;
}

const StyledImage = styled.img`
  display: inline-block;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    transition: opacity 400ms;
  }

  &.lazyloaded {
    transition: opacity 400ms;
    opacity: 1;
  }
`;

const Image: FC<ImageProps> = ({ alt, src, url, srcset, sizes, lazy, ratio, className = '' }) => {
  const { image, imageClass } = buildImageObject(
    {
      alt,
      role: !alt ? 'presentation' : undefined,
      sizes,
      src: src || url,
      srcSet: srcset ? buildSrcSet(srcset) : undefined
    },
    lazy
  );

  if (ratio) {
    return (
      <AspectRatio ratio={ratio} className={className || ''}>
        <StyledImage className={imageClass} {...image} />
      </AspectRatio>
    );
  }

  return <StyledImage className={`${className} ${imageClass}`} {...image} />;
};

export default Image;
