import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledMainStage = styled$1.div`
  .mainStage__grid {
    margin-bottom: ${space(11)};
    position: relative;

    ${mq('m')} {
      padding-top: ${space(4)};
      margin-bottom: ${space(16)};
    }
  }

  .mainStage__breadcrumb {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${({
  hasMedia
}) => getProperty('grid-row', {
  default: hasMedia ? '2' : '1',
  m: '1'
})};
    ${getTypography.small};
    color: ${colorSet('accent')};
    margin-top: ${({
  hasMedia
}) => !hasMedia ? space(15) : space(5)};
    margin-bottom: ${space(10)};

    ${mq('l')} {
      margin-top: 0;
    }
  }

  .mainStage__breadcrumbLink {
    border-bottom: none;
    ${getTypography.small};
    color: ${colorSet('color')};
    margin-left: ${space(2)};

    &:first-child {
      margin-left: 0;
    }
  }

  .mainStage__media {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 11'
})};
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-left: 0;
      grid-row: 2;
    }
  }

  .mainStage__media-ratio {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    box-sizing: border-box;

    ${mq('m')} {
      padding-bottom: 0;
      height: 660px;
    }

    img,
    video {
      object-fit: cover;
      height: 100%;
      transition: transform 0.2s;
    }

    > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .mainStage__headline,
  .mainStage__subline,
  .mainStage__excerpt {
    ${mq('m')} {
      transform: translateY(${space(10)});
    }
  }

  .mainStage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 8',
  l: '1 / span 7'
})};
    margin-top: auto;
    color: ${colorSet('accent')};

    &:first-child {
      margin-top: ${space(10)};
    }

    h1 {
      margin-top: ${space(7)};
      transition: transform 0.4s;
      transform-origin: center center;
      will-change: transform;

      ${mq('m')} {
        margin-top: 0;
      }
    }

    ${mq('m')} {
      margin-top: auto;
      grid-row: 2;
    }
  }

  .mainStage__breadcrumb + .mainStage__headline h1 {
    margin-top: 0;
  }

  .mainStage__subline {
    margin-top: ${space(5)};
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 4'
})};

    ${mq('m')} {
      grid-row: 3;
    }
  }

  .mainStage__excerpt {
    ${getProperty('grid-column', {
  default: '1 / span 6'
})};
    margin-top: ${space(10)};

    ${mq('m')} {
      grid-row: 4;
    }

    p {
      ${getTypography.copyIntro};
    }
  }

  .mainStage__link:hover {
    .mainStage__media img {
      transform: scale(1.05);
    }

    .mainStage__headline h1 {
      transform: scale(1.02);
    }
  }
`;

export default StyledMainStage;
