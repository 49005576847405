import styled$1, { css } from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid } from '../Grid/Grid.js';

const StyledStage = styled$1.div`
  position: relative;
  margin-bottom: ${space(10)};

  ${mq('m')} {
    padding-top: ${space(4)};
  }

  .stage__breadcrumb {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${({
  hasMedia
}) => getProperty('grid-row', {
  default: hasMedia ? '2' : '1',
  m: '1'
})};
    ${getTypography.small};
    color: ${colorSet('accent')};
    margin-top: ${({
  hasMedia
}) => !hasMedia ? space(15) : space(5)};
    margin-bottom: ${space(10)};

    ${mq('l')} {      
      margin-top: 0;
    }

    span {
      margin-left: ${space(2)};
    }
  }

  .stage__breadcrumbLink {
    border-bottom: none;
    ${getTypography.small};
    color: ${colorSet('color')};
    margin-left: ${space(2)};

    &:first-child {
      margin-left: 0;
    }
  }

  .stage__image {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '6 / span 7',
  l: '7 / span 6'
})};
    ${getProperty('grid-row', {
  m: '2 / span 4'
})};
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .stage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 8',
  l: '3 / span 6'
})};
    ${getProperty('grid-row', {
  m: '3 / auto'
})};
    align-self: end;
    position: relative;
    margin-bottom: ${space(6)};
    color: ${colorSet('accent')};
  }

  .stage__news-format {
    color: ${colorSet('color')};
    margin-bottom: ${space(1)};
  }

  .stage__info {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5',
  l: '3 / span 4'
})};
    ${getProperty('grid-row', {
  m: '4 / auto'
})};
  }

  .stage__tags {
    margin-bottom: ${space(2)};
  }

  .stage__subline {
    position: relative;
  }

  .stage__meta {
    ${getGrid({
  default: 6,
  m: 5,
  l: 4
}, false)}
    margin-top: ${space(8)};
    align-items: center;
  }

  .stage__author-image-wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 3',
  m: '1 / span 2'
})};
  }

  .stage__author-image {
    border-radius: 50%;
    overflow: hidden;
  }

  .stage__meta-aside {
    margin-top: ${space(4)};

    ${mq('m')} {
      margin-top: 0;
    }

    ${({
  authorUser
}) => authorUser && authorUser.image ? getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 3',
  l: '3 / span 2'
}) : getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5',
  l: '1 / span 4'
})};
  }

  .stage__author {
    position: relative;
    font-weight: bold;
    letter-spacing: 2px;  
  }

  .stage__post-date {
    display: block;
    position: relative;
    ${getTypography.small};
    margin-top: ${space(1)};

    &:first-of-type {
      display: inline;
    }
  }

  .stage__meta-entry {
    ${getTypography.small};
    margin-left: ${space(1)};
    border-left: 1px solid ${colorSet('accent')};
    padding-left: ${space(1)};
  }

  img,
  video {
    width: 100%;
  }

  .stage__excerpt {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '6 / span 7',
  l: '7 / span 6'
})};
    margin-top: ${space(10)};

    ${mq('m')} {
      grid-row: ${props => props.hasMedia ? '6' : '5'};
    }

    p {
      ${getTypography.copyIntro};
    }
  }

  ${({
  hasMedia,
  isLarge
}) => isLarge && hasMedia && css(["", "{padding-bottom:", ";}.stage__image{", ";", ";}.stage__headline,.stage__info,.stage__excerpt,.stage__meta{", "{transform:translateY(", ");}}.stage__headline{", ";", ";", ";}.stage__info{", ";", ";}.stage__image > div > div{", "{padding-bottom:0;height:660px;}img,video{object-fit:cover;}}.stage__excerpt{", ";}"], mq('m'), space(23), getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 11'
}), getProperty('grid-row', {
  m: '2'
}), mq('m'), space(13), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 8',
  l: '1 / span 7'
}), getTypography.hero, getProperty('grid-row', {
  m: '2'
}), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 6'
}), getProperty('grid-row', {
  m: '3'
}), mq('m'), getProperty('grid-row', {
  m: '4'
}))}
`;

export default StyledStage;
