import React, { FC } from 'react';
import styled from 'styled-components';

import Image from '../Image/Image';
import { buildSrcSet } from '../Image/helper';

type SourceProps = {
  srcset: { [key: string]: string };
  media: string;
  lazy?: boolean;
};

export const Source = ({ srcset, media, lazy }: SourceProps) => {
  let source: { [key: string]: any } = {
    srcSet: buildSrcSet(srcset),
    media
  };

  if (lazy) {
    source = {
      ...source,
      srcSet: undefined,
      'data-srcset': buildSrcSet(srcset),
      className: 'lazyload'
    };
  }

  return <source {...source} />;
};

export interface PictureProps {
  src: string;
  alt: string;
  lazy?: boolean;
  srcset?: { [key: string]: string };
  className?: string;
}

const StyledPicture = styled.picture`
  display: block;

  img {
    width: 100%;
  }
`;

const Picture: FC<PictureProps> = ({ src, srcset, alt, children, lazy, className }) => (
  <StyledPicture className={className}>
    {children}
    <Image src={src} srcset={srcset} alt={alt} lazy={lazy} />
  </StyledPicture>
);

Picture.defaultProps = {
  lazy: false
};

export default Picture;
