import React, { FC } from 'react';

interface DataObject {
  [key: string]: any;
}

interface DataProviderProps {
  data: DataObject;
}

const defaultData = {};

export const DataContext = React.createContext<DataObject>(defaultData);

const DataProvider: FC<DataProviderProps> = ({ children, data }) => (
  <DataContext.Provider value={data}>{children}</DataContext.Provider>
);

export const DataConsumer = DataContext.Consumer;

export default DataProvider;
