import styled$1, { css } from 'styled-components';
import { space, colorSet, getProperty, mq } from '@ccg/core';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';
import { getGrid } from '../Grid/Grid.js';

const StyledInlineContact = styled$1.div`
  position: relative;
  ${getGrid()};
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};

  ${({
  hasUser
}) => hasUser && css(["background:", ";margin-top:0;margin-bottom:0;padding-top:", ";padding-bottom:", ";"], colorSet('background'), space(10), space(10))};

  .inlineContact__author-image-wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 3',
  m: '1 / span 2',
  l: '3 / span 2'
})};
    margin-bottom: ${space(3)};

    ${mq('m')} {
      margin-bottom: 0;
    }
  }

  .inlineContact__author-image {
    border-radius: 50%;
    overflow: hidden;
  }

  .inlineContact__wrapper {
    ${({
  hasUser
}) => !hasUser ? css(["", ""], getTextColumns) : getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5',
  l: '3 / span 5'
})};
  }

  .inlineContact__author-image-wrapper + .inlineContact__wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 5',
  l: '5 / span 5'
})};
  }

  .inlineContact__author-position {
    margin-bottom: ${space(2)};
  }

  .inlineContact__link {
    margin-top: ${space(4)};
    color: ${colorSet('accent')};
  }

  .inlineContact__author-social-media {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style-type: none;

      ${mq('m')} {
        flex-wrap: nowrap;
      }
    }

    li {
      display: inline-block;
      margin: ${space(5)} ${space(3)} 0 0;
      width: 40px;
      height: 40px;

      svg {
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(0.95);
      }
    }
  }
`;

export default StyledInlineContact;
