import styled$1 from 'styled-components';
import { space, getProperty, colorSet, mq } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledFacs = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  row-gap: ${space(10)};

  .facts__fact {
    ${props => props.hasEvenNumberOfFacts ? getProperty('grid-column', {
  default: 'span 6'
}) : getProperty('grid-column', {
  default: 'span 6',
  m: 'span 4'
})};

    position: relative;
    padding: ${space(8)};
    background: ${colorSet('backgroundSecondary')};
    color: ${colorSet('colorSecondary')};

    ${mq('l')} {
      padding: ${space(18)};
    }

    a {
      &:hover {
        color: ${colorSet('colorSecondary')};
        background: ${colorSet('accent')};
      }
    }

    p {
      margin-bottom: ${space(2)};

      &:last-child {
        margin: 0;
        padding: 0;
      }
    }

    .highlight {
      color: ${colorSet('accent')};
      margin: 0;
      word-break: break-all;
      letter-spacing: -0.6px;
      line-height: 1;

      & + p:not(.highlight-text) {
        margin-top: ${space(2)};
      }
    }

    .highlight-small {
      margin-bottom: ${space(3)};
      color: ${colorSet('accent')};
    }
  }
`;

export default StyledFacs;
