import styled$1 from 'styled-components';
import { color, mq, space, zIndex } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const GalleryLine = styled$1.div`
  position: fixed;
  width: 2px;
  height: 100vh;
  top: 0;
  left: 10vw;
  background: ${color('bright')};

  ${mq('m')} {
    left: 25vw;
  }
`;
const StyledGallery = styled$1.div`
  margin: ${space(12)} 0;
  position: relative;

  .mediaContent__media-wrapper {
    align-self: center;
  }

  .gallery__ratio {
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      object-fit: contain;

      &:nth-of-type(2) {
        width: 95%;
      }
    }
  }

  .gallery__icon {
    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      left: ${space(4)};
      top: ${space(4)};
      height: auto;
    }

    p {
      color: ${color('light')};
      margin-right: ${space(4)};
    }
  }

  .gallery__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .caption__caption p {
    font-weight: bold;
    ${getTypography.copyIntro}

    ${mq('m')} {
      padding-left: 60px;
    }
  }

  .caption__caption p:first-of-type {
    font-weight: bold;
    ${getTypography.small}

    span {
      color: ${color('highlight01')};
    }
  }

  .gallery__button {
    z-index: ${zIndex('base')};
    width: 100%;
    height: 100%;
  }

  .gallery__prev,
  .gallery__next {
    position: absolute;
    bottom: ${space(-12)};
    z-index: 1;

    ${mq('m')} {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .gallery__prev {
    right: ${space(15)};

    ${mq('m')} {
      right: auto;
      left: ${space(-12.5)};
    }
  }

  .gallery__next {
    right: 0;

    ${mq('m')} {
      right: ${space(-12.5)};
    }
  }
`;

export default StyledGallery;
export { GalleryLine };
