import React, { FC } from 'react';

export interface TextareaProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  id: string;
  label?: string;
  description?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  required?: boolean;
  pattern?: string;
  autocomplete?: string;
  placeholder?: string;
  type?: string;
  rows?: number;
}

const Textarea: FC<TextareaProps> = ({
  className = '',
  id,
  label,
  value,
  required,
  placeholder,
  description,
  onChange,
  onFocus,
  error,
  disabled,
  autocomplete,
  rows = 4,
  errorMessage
}) => (
  <div className={className}>
    <label htmlFor={id}>
      <span className="textarea__labelText">{label}</span>
      {!required && <span className="textarea__optional">optional</span>}
      {description && <span className="textarea__description">{description}</span>}
    </label>

    <textarea
      id={id}
      name={id}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      aria-invalid={error}
      aria-required={required}
      required={required}
      disabled={disabled}
      autoComplete={autocomplete}
      rows={rows}
    />

    {error && errorMessage && <span className="textarea__error">{errorMessage}</span>}
  </div>
);

export default Textarea;
