import styled$1, { css } from 'styled-components';
import { colorSet, mq, space } from '@ccg/core';
import { getOuterGridContainer, getInnerGridContainer } from '../Grid/Grid.js';

const StyledMainContainer = styled$1.main`
  ${getOuterGridContainer()}
  position: relative;
  background: ${colorSet('background')};
  color: ${colorSet('color')};
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.77, 1), background 0.1s 0.8s ease-in-out,
    color 0.1s 0.8s ease-in-out;
  min-height: 100vh;

  ${({
  navigationIsOpen
}) => navigationIsOpen && css(["transform:translate(0,125vh);pointer-events:none;", "{transform:none;pointer-events:all;}"], mq('l'))}
  .mainContainer__inner {
    ${getInnerGridContainer()}
    position: relative;
  }

  ${({
  hasAccentLine
}) => hasAccentLine && css(["section{position:relative;}.stage__excerpt:before,section:before{content:'';position:absolute;display:none;width:2px;height:100%;background:", ";margin-left:", ";", "{display:block;margin-left:", ";}", "{margin-left:calc(51px + ((100% - 333px) / 12) * 2);left:", ";}}.stage__excerpt:before{height:100vh;", "{margin-left:calc(-75px + ((100% - 90px) / 7) * -5);}", "{margin-left:calc(-120px + ((100% - 150px) / 6) * -4);left:0;}}"], colorSet('accent'), space(5), mq('m'), space(13), mq('l'), space(13), mq('m'), mq('l'))}
`;

export default StyledMainContainer;
