import styled$1 from 'styled-components';
import { space, color, mq, getProperty } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledFilter = styled$1.aside`
  position: relative;
  padding: ${space(20)} 0;
  background: ${color('light')};

  .filter__selected {
    position: relative;
    grid-column: 1 / span 12;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: ${space(2)};

    ${mq('m')} {
      align-items: center;
      flex-direction: row;
    }

    &:before {
      opacity: ${props => props.open ? 1 : 0};
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 1px;
      width: 120px;
      background: ${color('bright')};
      transition: opacity 0.2s ease-in-out;
    }

    button {
      display: inline-flex;
      align-items: center;
      margin-right: ${space(6)};
      color: ${color('bright')};
      padding: ${space(2)} 0;

      &:focus,
      &:hover {
        outline: none;
        color: ${color('highlight01')};

        svg {
          fill: ${color('highlight01')};
        }
      }

      ${mq('m')} {
        padding: 0;
      }
    }
  }

  .filter__selected-title {
    color: ${color('bright')};
    margin-right: ${space(6)};
  }

  .filter__values {
    grid-column: 1 / span 6;
    display: none;

    button {
      ${getProperty('grid-column', {
  default: 'span 6',
  m: 'span 2'
})}
      text-align: left;
      display: block;
      padding: ${space(3)} 0;
      padding-right: ${space(2)};

      &:hover {
        color: ${color('highlight01')};
      }

      &[aria-pressed='true'],
      &:focus {
        outline: none;
        color: ${color('bright')};
      }

      ${mq('m')} {
        padding: ${space(4)} 0;
      }
    }
  }

  .filter__values[aria-hidden='false'] {
    ${getGrid({
  default: 6
}, false)};
  }

  .filter__groups {
    grid-column: 1 / span 12;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 2px;
      transform: translateX(-100vw);
      background: ${color('neutral')};
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 2px solid ${color('neutral')};
    }

    button {
      position: relative;
      grid-column: span 1;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding-bottom: ${space(4)};
      padding-right: ${space(7)};

      ${mq('m')} {
        padding-right: ${space(15)};
      }

      &[aria-pressed='true'],
      &:focus {
        outline: none;
        color: ${color('bright')};
      }

      svg {
        transition: transform 0.2s ease-in-out;
        transform-origin: center;
        overflow: visible;
      }

      &[aria-pressed='true'] svg {
        transform: rotate(180deg) translateY(-4px);
      }
    }
  }
`;

export default StyledFilter;
