import { css } from 'styled-components';
import { unit, cubicBezier } from '@4rk/modular-system';

const timing = props => cubicBezier('acceleration', 'cubicBezier')(props);

const duration = props => unit(['gravity', 'volume'], 'transitionDuration')(props);
const getTransitionProperty = (property = 'all', delay) => props => css(["transition:", " ", " ", " ", ";"], property, duration(props), timing(props), delay || '');
const getTransition = props => `
  opacity: ${props.isVisible ? 1 : 0};
  transition: all ${duration(props)} ${timing(props)};
`;

export { getTransition, getTransitionProperty };
