/*
  Do not edit directly
  Run `yarn tokens` to generate new variables from design tokens
*/
const tokens = {
  breakpoints: {
    s: '420px',
    m: '768px',
    l: '1280px',
    xl: '1440px'
  },
  colors: {
    bright: '#ff005f',
    light: '#fff',
    dark: '#000',
    neutral: '#EEEEEE',
    highlight01: '#3c009c',
    highlight02: '#7bd1e6',
    highlight03: '#0000fe',
    highlight04: '#ffcd64'
  },
  colorSets: {
    neutral: {
      color: '#000',
      colorSecondary: '#fff',
      background: '#fff',
      backgroundSecondary: '#3c009c',
      accent: '#ff005f',
      neutral: '#EEEEEE'
    }
  },
  grid: {
    columns: {
      default: 6,
      m: 12
    },
    gutters: {
      default: '0',
      m: '15px',
      l: '30px'
    }
  },
  modular: {
    values: {
      size: 0.5,
      form: 0.5,
      volume: 0.5,
      gravity: 0.5,
      acceleration: 0.54
    },
    ranges: {
      fontFamily: [{
        accent: '"Playfair Display", serif',
        accentItalic: '"Playfair Display Italic", serif',
        default: 'Helvetica Neue, sans-serif'
      }, {
        accent: '"Playfair Display", serif',
        accentItalic: '"Playfair Display Italic", serif',
        default: 'Helvetica Neue, sans-serif'
      }, {
        accent: '"Playfair Display", serif',
        accentItalic: '"Playfair Display Italic", serif',
        default: 'Helvetica Neue, sans-serif'
      }, {
        accent: '"Playfair Display", serif',
        accentItalic: '"Playfair Display Italic", serif',
        default: 'Helvetica Neue, sans-serif'
      }],
      spacingBase: [4],
      transitionDuration: ['0.8s'],
      cubicBezier: ['0.54, 0.0, 0.54, 1', '1, 0.0, 1, 1']
    }
  },
  spacing: {
    base: 4,
    scale: {
      ratio: 2,
      base: 4
    }
  },
  typography: {
    scale: {
      ratio: [1.43, 1.43],
      base: [16, 17],
      width: [375, 980],
      breakpoints: 4,
      corrections: {}
    }
  },
  fonts: {
    default: {
      family: 'Helvetica Neue, sans-serif'
    },
    accent: {
      family: '"Playfair Display", serif'
    },
    accentItalic: {
      family: '"Playfair Display Italic", serif'
    }
  },
  zIndexes: {
    bottom: -1,
    base: 1,
    middle: 2,
    top: 3,
    modal: 99,
    overlay: 999
  }
};

export { tokens };
