import React, { FC, LegacyRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export interface RecaptchaBadgeProps {
  id: string;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  onChange: (token: string | null) => void;
  recaptchaRef: LegacyRef<ReCAPTCHA>;
  recaptchaSitekey: string;
}

const RecaptchaBadge: FC<RecaptchaBadgeProps> = React.forwardRef(
  ({ id, className, error, errorMessage, onChange, recaptchaRef, recaptchaSitekey }) => {
    return (
      <div id={id} className={className}>
        <ReCAPTCHA
          ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
          sitekey={recaptchaSitekey}
          onChange={onChange}
        />
        {error && errorMessage && <span className="input__error">{errorMessage}</span>}
      </div>
    );
  }
);

export default RecaptchaBadge;
