import styled$1 from 'styled-components';
import { space, colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentTopLine } from '../../styles/typography.js';
import Text$1 from '../../basics/Text/Text.js';

const StyledFact = styled$1(Text$1)`
  position: relative;

  .fact__content {
    position: relative;
    grid-column: 1 / span 6;
    align-self: center;
    ${getAccentTopLine};
    margin: 0 0 ${space(12)} 0;
    padding: ${space(8)};
    color: ${colorSet('colorSecondary')};
    ${getAccentTopLine}

    p,a,.highlight {
      position: relative;
      z-index: 2;
    }

    a {
      &:hover {
        color: ${colorSet('colorSecondary')};
        background: ${colorSet('accent')};
      }
    }

    p {
      margin: 0;
    }

    .highlight {
      display: block;
      color: ${colorSet('accent')};
      margin-bottom: ${space(3)};
      word-break: break-all;
      letter-spacing: -0.6px;
      line-height: 1;
    }

    ${mq('m')} {
      grid-column: 1 / span 5;
      grid-row: 1 / span 10;
      margin: 0;
    }

    ${mq('l')} {
      padding: ${space(18)};
    }

    &:before {
      display: none;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: ${colorSet('backgroundSecondary')};
      width: 100%;

      ${mq('m')} {
        margin-left: 0;
      }
    }
  }
`;

export default StyledFact;
