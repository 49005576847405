import styled$1 from 'styled-components';
import { mq, space, color, getProperty, zIndex, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../../../styles/typography.js';
import { getGrid } from '../../../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledControls = styled$1.div`
  .controls__next,
  .controls__prev {
    ${getTypography.copy};
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    ${mq('m')} {
      display: block;
    }

    & > div {
      display:inline-block;
    }
  }

  .controls__next  {
    right: ${space(3)};
    text-align: right; 
    color: ${color('light')};
  }

  .controls__prev {
    left: ${space(3)};
    text-align: left; 
    color: ${color('light')};
  }

  .controls__close {
    grid-row: 1 / span 2;
    color: ${color('light')};
    ${getProperty('grid-column', {
  default: '11 / span 1'
})}
  }

  .controls__header {
    ${getGrid()}
    width: calc(100% - ${space(12)});
    top: ${space(5)};
    position: absolute;
    z-index: ${zIndex('base')};

    ${mq('m')} {
      width:100%
    }
  }

  .controls__content {
    ${getGrid()}
    width: calc(100% - ${space(12)});
    position: relative;
    z-index: ${zIndex('base')};
    margin-top: ${space(7)};
    margin-bottom: ${space(5)};

    button {
      align-self: end; 
    }

    ${mq('m')} {
      width:100%
    }
  }

  
  .controls__bullets {
    ${getProperty('grid-column', {
  default: '1 / span 5',
  m: '1 / span 4'
})}
  }

  .controls__caption {
    ${getProperty('grid-column', {
  default: '1 / span 5',
  m: '1 / span 6'
})}
    grid-row: 1;
    margin-bottom: ${space(7)};
    color: ${color('light')};
  }

  .controls__bullets {
    grid-row: 2;
    
    button {
      margin-right: 5px;
    }
    
    span {
      width: 5px;
      height: 5px;
      background: ${color('light')};
      display: block;
      transition: width 0.2s ease-in-out;

      &.is-active {
        background: ${colorSet('accent')};
        width: 28px;
      }
    }
  }
`;

export default StyledControls;
