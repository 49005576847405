import React, { FC } from 'react';

export interface InputProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  files: string[];
  description?: string;
  id: string;
  label?: string;
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
}

const Input: FC<InputProps> = ({
  className,
  disabled,
  error,
  errorMessage,
  files,
  description,
  id,
  label,
  multiple,
  onChange,
  onFocus,
  placeholder,
  required
}) => {
  return (
    <div className={className}>
      <label htmlFor={id}>
        <span className="upload__labelText">{label}</span>
        {!required && <span className="upload__optional">optional</span>}
        {description && <span className="upload__description">{description}</span>}
      </label>

      <div className="upload__wrapper">
        {/* value can not be synched because file input is a uncontrolled component see
        -> we use files to save files data
        -> TOOD do something with the files
        https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag */}
        <input
          id={id}
          name={id}
          type="file"
          multiple={multiple}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          aria-invalid={error}
          aria-required={required}
          disabled={disabled}
        />
      </div>
      {error && errorMessage && <span className="upload__error">{errorMessage}</span>}
    </div>
  );
};

export default Input;
