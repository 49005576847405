import styled$1 from 'styled-components';
import { zIndex, space, getProperty, mq, colorSet } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledFooter = styled$1.div`
  position: relative;
  z-index: ${zIndex('top')};
  padding-top: ${space(20)};

  .footer__icon {
    position: absolute;
    width: 406px;
    height: 406px;
    right: -93px;
    bottom: -134px;

    svg {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .footer__wrapper {
    ${getGrid()};
    min-height: 259px;
    align-content: end;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .footer__main-navigation {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10'
})};
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: flex-end;

    ${mq('m')} {
      min-height: auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    li {
      margin: ${space(11)} 0;

      ${mq('m')} {
        display: inline-block;
        margin-right: ${space(10)};
      }
    }

    a {
      letter-spacing: 2px;
    }

    a.is-active {
      color: ${colorSet('accent')};

      .icon__icon {
        background: none;
      }

      &:hover {
        background: transparent;
        color: inherit;
      }
    }
  }

  .footer__social-media {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10'
})};

    ul {
      display: flex;
      flex-wrap: wrap;

      ${mq('m')} {
        flex-wrap: nowrap;
      }
    }

    li {
      display: inline-block;
      margin: ${space(7)} ${space(6)} 0 0;
      width: 40px;
      height: 40px;

      svg {
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(0.95);
      }
    }
  }

  .footer__logo {
    display: none;

    ${mq('m')} {
      display: block;
      margin: ${space(11)} ${space(13)} 0 0;
    }
  }
`;

export default StyledFooter;
