import styled$1, { css } from 'styled-components';
import { space, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTransition } from '../../styles/transitions.js';

const StyledCategoryTeaser = styled$1.div`
  ${getTransition}
  display:flex;
  grid-column: 1 / span 6;
  margin: ${space(12)} 0;

  ${mq('m')} {
    grid-column: ${props => props.isEven ? '7 / span 6' : '1 / span 6'};
    margin-top: ${props => props.isEven ? space(25) : space(8)};
    margin-bottom: ${space(20)};
    padding-right: ${space(10)};

    ${props => props.orientation === 'vertical' && css(["min-height:", "px;"], props.height + 10)}
  }

  ${mq('l')} {
    &:nth-of-type(4n - 1),
    &:nth-of-type(4n) {
      grid-column: ${props => props.isEven ? '8 / span 5' : '2 / span 5'};
    }

    &:first-of-type {
      grid-column: ${props => props.isEven ? '7 / span 6' : '1 / span 6'};
    }
  }

  .categoryTeaser__content {
    ${mq('m')} {
      ${props => props.orientation === 'vertical' && css(["margin-left:120px;"])}
    }
  }

  .categoryTeaser__headline {
    display: inline-block;
    position: relative;
    margin-bottom: ${space(4)};

    h2 {
      color: ${colorSet('accent')};
      display: inline;
      word-break: break-word;
    }

    ${mq('m')} {
      ${props => props.orientation === 'vertical' && css(["word-spacing:100vw;transform:rotate(90deg);transform-origin:bottom left;padding-left:18px;"])}
    }
  }

  .categoryTeaser__cta {
    display: inline-block;
    position: relative;
    justify-self: left;
    ${getTypography.copy};
    letter-spacing: 2px;
    margin-top: ${space(4)};
    padding-bottom: ${space(1)};

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: ${colorSet('accent')};
      transition: transform 0.1s ease-in-out;
    }
  }

  .categoryTeaser__link {
    ${mq('m')} {
      ${props => props.orientation === 'vertical' && css(["margin-top:-75px;"])}
    }
  }

  a:hover .categoryTeaser__cta:after {
    transform: translateY(${space(-0.5)});
  }
`;

export default StyledCategoryTeaser;
