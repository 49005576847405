/* eslint-disable prefer-template, no-bitwise, no-restricted-properties */

/**
 * Returns zIndex from theme referenced by id
 * @param  {string} id - Identifier for the zIndex in the configuration map.
 * @returns {string} Resulting zIndex from configuration file
 * @example z-index: ${zIndex('base')};
 */
export const zIndex = (id: string) => ({ theme }: any) => {
  if (!theme) {
    throw new Error('No theme object provided');
  }

  const { zIndexes } = theme;

  if (!zIndexes) {
    throw new Error('zIndexes are not defined in theme');
  }

  if (!Object.prototype.hasOwnProperty.call(zIndexes, id)) {
    throw new Error(`zIndex ${id} not known`);
  }

  return zIndexes[id];
};

/**
 * CSS to hide content visually but remain accessible to screen readers.
 * @returns {string} - Returns CSS to hide content
 * @example ${hideVisually()}
 */
export const hideVisually = () => `
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

`;

/**
 * CSS to reset button styling.
 * @returns {string} - Button reset styles
 * @example ${btnReset()}
 */
export const btnReset = () => `
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

/**
 * Turns a hex value in a rgba value
 * @param  {string} hex - Hex color value
 * @param  {number} opacity - Opacity value
 * @returns {string} - Hex value as rgba value
 * @example color: ${hexToRgba("#fff", 0.5)};
 */
export const hexToRgba = (hex: string, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join(''); /* eslint-disable-line */
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
};

export const stripUnit = (value, unitReturn = true) => {
  if (typeof value !== 'string') return unitReturn ? [value, undefined] : value;
  const matchedValue = value.match(/^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/);

  if (unitReturn) {
    if (matchedValue) return [parseFloat(value), matchedValue[2]];
    return [value, undefined];
  }

  if (matchedValue) return parseFloat(value);
  return value;
};

export const modularScale = (steps, base, ratio) => {
  const [realBase, unit] = typeof base === 'string' ? stripUnit(base, true) : [base, ''];
  return `${realBase * Math.pow(parseFloat(ratio), parseFloat(steps))}${unit}`;
};
