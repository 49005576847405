import styled$1, { css } from 'styled-components';
import { Textarea as Textarea$1, color, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { space } from '../../styles/spacing.js';

const Textarea = styled$1(Textarea$1)`
  textarea {
    display: block;
    width: 100%;
    resize: none;
    background: ${color('neutral')};
    outline: none;
    padding: ${space(3)} ${space(3)} ${space(2)};
    border: none;

    ${props => props.error && css(["border:2px solid ", ";padding:", ";"], colorSet('accent'), space(2))}
  }

  textarea::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${space(2)};
  }

  .textarea__labelText,
  textarea {
    ${getTypography.copy}
  }

  .textarea__optional {
    margin-left: ${space(2)};
    ${getTypography.small}
  }

  .textarea__description,
  .textarea__error {
    display: block;
    ${getTypography.small}
  }

  .textarea__error {
    color: ${colorSet('accent')};
  }
`;

export default Textarea;
