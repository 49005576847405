import styled$1, { css } from 'styled-components';
import { space, colorSet, zIndex, getProperty } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledTeaserOverview = styled$1(Grid$1)`
  position: relative;
  padding-top: ${space(12)};
  padding-bottom: ${space(5)};
  background: ${colorSet('background')};
  z-index: ${zIndex('middle')};

  .teaserOverview__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})}
    color: ${colorSet('accent')};
    display: flex;
    align-items: flex-end;

    h2 {
      max-width: 100%;
      margin-right: ${space(5)};
      flex-shrink: 0;
    }

    ${props => props.hasLink && css(["&:hover .teaserOverview__line{transition:width 0.2s ease-in-out;width:180px;}"])}
    .teaserOverview__line {
      position: relative;
      width: 100%;
      max-width: 170px;
      height: 2px;
      background: ${colorSet('accent')};
      margin-bottom: 8px;
      transition: width 0.2s ease-in-out;

      ${props => props.hasLink && css(["&:after{content:' ';position:absolute;top:-3px;right:1px;width:6px;height:6px;border-right:2px solid ", ";border-bottom:2px solid ", ";transform:rotate(-45deg);}"], colorSet('accent'), colorSet('accent'))}
    }
  }
  
  .teaserOverview__excerpt {
    ${getProperty('grid-column', {
  default: '1 / span 5',
  m: '1 / span 6'
})}
    grid-row: 2;
 
  }

  .teaserOverview__headline + .teaserOverview__excerpt {
    margin-top: ${space(2)};
  }
`;

export default StyledTeaserOverview;
