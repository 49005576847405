import styled$1 from 'styled-components';
import { zIndex, colorSet, mq, space, getProperty } from '@ccg/core';

const StyledMainStageAnimation = styled$1.div`
  .mainStageAnimation__wrapper,
  .mainStageAnimation__progress,
  .mainStageAnimation__headline {
    z-index: ${({
  playedAnimation
}) => playedAnimation ? 0 : zIndex('modal')};
  }

  .mainStageAnimation__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: ${({
  showAnimation
}) => !showAnimation && 'none'};

    &:before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${colorSet('background')};
    }

    ${mq('m')} {
      padding-top: ${space(4)};
      margin-bottom: ${space(16)};
    }
  }

  .mainStageAnimation__image-wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 11'
})};
    grid-row: 1;
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-left: 0;
      grid-row: 1;
    }
  }

  .mainStageAnimation__image-ratio {
    height: 0;
    padding-bottom: calc(3 / 2 * 100%);
    position: relative;
    box-sizing: border-box;

    ${mq('m')} {
      padding-bottom: 0;
      height: 660px;
    }

    img {
      object-fit: cover;
    }

    * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .mainStageAnimation__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    align-self: center;
    position: relative;
    margin: auto;
    color: ${colorSet('accent')};
    grid-row: 1;

    padding-right: ${space(5)};
    padding-left: ${space(5)};

    ${mq('m')} {
      padding-right: ${space(13)};
      padding-left: ${space(13)};
    }

    ${mq('l')} {
      padding-right: ${space(16)};
      padding-left: ${space(16)};
    }

    h1 {
      white-space: nowrap;

      ${mq('m')} {
        font-size: 500px;
        line-height: 100px;
        letter-spacing: -2.48px;
      }
    }
  }

  .mainStageAnimation__progress {
    position: absolute;
    top: 0;
    background: ${colorSet('accent')};
    height: 2px;

    ${mq('xl')} {
      left: calc((1440px - 100vw) / 2);
    }
  }

  .mainStage__media {
    opacity: ${({
  playedAnimation
}) => playedAnimation ? 1 : 0};
  }

  .mainStage__headline {
    ${mq('m')} {
      transform: translate(
        ${({
  playedAnimation
}) => playedAnimation ? '0, 40px' : '50px, 40px'}
      );
      opacity: ${({
  playedAnimation
}) => playedAnimation ? 1 : 0};
      transition: all 0.4s 0.4s ease-in-out;
    }
  }

  .mainStage__subline {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, transparent 0%, white 10%);
      width: 100%;
      content: '';
      transform: ${({
  playedAnimation
}) => playedAnimation ? 'scaleX(0) scaleY(1.1)' : 'scaleX(1.2) scaleY(1.1)'};
      height: 100%;
      transform-origin: right center;
      transition: all 0.4s 0.6s ease-in-out;
    }
  }
`;

export default StyledMainStageAnimation;
