import { useRouter } from 'next/router';
import { i18n } from '../i18n';

export const redirect = (ctx, path) => {
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    document.location.pathname = path;
  }
};

export const encodeMemberSlug = ({ firstName, lastName }) =>
  `${firstName ? `${firstName.replace(' ', '_').toLowerCase()}-` : ''}${
    lastName ? lastName.replace(' ', '_').toLowerCase() : ''
  }`;

export const decodeMemberSlug = slug => {
  // TODO: maybe a better solution for double names (e.g. "-" within lastname)
  const parts = slug.split('-');
  const lastName = parts.pop().replace('_', ' ');
  const firstName = parts.join('-').replace('_', ' ');

  return {
    firstName,
    lastName
  };
};

export const getNextLinkProps = linkTo => {
  if (!linkTo) return undefined;
  const { element, url, type, text, customText, target } = linkTo;
  const { asPath } = useRouter();

  if (!element && !url) return null;

  // If type is url return default a tag
  if (type === 'url') {
    return { label: text || customText, href: url, target, component: 'a', icon: 'external' };
  }

  const baseProps = {
    label: text || customText || element.title,
    target
  };

  const { language } = i18n;
  const { uri, slug } = element;

  const isActiveItem = path => {
    return asPath === `/${language}${path}` || asPath === path || asPath.includes(path);
  };

  if (type === 'entry' && element && element.uri) {
    if (element.__typename === 'cases_cases_Entry') {
      return {
        ...baseProps,
        href: { pathname: '/case', query: { slug } },
        asPath: `/${uri}`,
        isActive: isActiveItem(`/${uri}`)
      };
    }

    if (element.__typename === 'jobs_jobs_Entry') {
      return {
        ...baseProps,
        href: { pathname: '/job', query: { slug } },
        asPath: `/${uri}`,
        isActive: isActiveItem(`/${uri}`)
      };
    }

    if (element.__typename === 'news_news_Entry') {
      return {
        ...baseProps,
        href: { pathname: '/news', query: { slug } },
        asPath: `/${uri}`,
        isActive: isActiveItem(`/${uri}`)
      };
    }

    return {
      ...baseProps,
      href: { pathname: '/standard', query: { uri } },
      asPath: `/${uri}`,
      isActive: isActiveItem(`/${uri}`)
    };
  }

  // TODO: Check if groupHandle exists
  if (type === 'category' && element && element.uri) {
    return {
      ...baseProps,
      href: {
        pathname: '/category',
        query: { slug, group: element.groupHandle }
      },
      asPath: `/${uri}`,
      isActive: isActiveItem(`/${uri}`)
    };
  }

  return {
    ...baseProps,
    href: url
  };
};

export const removeSpecialCharacters = string => string.replace(/(<[^>]+>|\*|&shy;)/g, '');

export const getCiColors = props => {
  let clientCi = {
    background: null,
    accent: null,
    text: null
  };

  if (props.data && props.data.entry && props.data.entry.client && props.data.entry.client[0]) {
    clientCi = {
      background: props.data.entry.client[0].ciColorPrimary,
      accent: props.data.entry.client[0].ciColorSecondary,
      text: props.data.entry.client[0].ciColorText
    };
  }

  return clientCi;
};

export const getColorTheme = props =>
  props.data && props.data.entry && props.data.entry.colorTheme
    ? props.data.entry.colorTheme
    : 'magenta';

export const getBackground = props =>
  props.data && props.data.entry && props.data.entry.background
    ? props.data.entry.background
    : 'white';

export const getContentType = props =>
  props.data && props.data.entry && props.data.entry.contentType
    ? props.data.entry.contentType
    : 'neutral';

export const updateModularTokens = (tokens, contentType) => {
  let modularValues = {};

  if (contentType === 'technical') {
    modularValues = {
      density: 0,
      form: 0,
      size: 0
    };
  } else if (contentType === 'editorial') {
    modularValues = {
      density: 0.5,
      form: 1,
      size: 0.5
    };
  } else if (contentType === 'advertising') {
    modularValues = {
      density: 1,
      form: 1,
      size: 0.5
    };
  }

  const { modular, ...restTokens } = tokens;

  return {
    modular: {
      ...modular,
      values: {
        ...modular.values,
        ...modularValues
      }
    },
    ...restTokens
  };
};
