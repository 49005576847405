import styled$1, { css } from 'styled-components';
import { space, colorSet, getProperty } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledContainer = styled$1.div`
  padding-top: ${space(12)};
  position: relative;
  
  .container__headline {
    ${getGrid()}
    margin-bottom: ${space(4)};

    h2 {
      color: ${colorSet('accent')};
      ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})}
      display: flex;
      align-items: flex-end;

      &::after {
        content: " ";
        position: relative;
        width: 170px; 
        height: 2px;
        margin-left: ${space(5)};
        background: ${colorSet('accent')};
        margin-bottom: 8px;
      } 
    }
  }

  ${props => props.variant === 'default' && css(["background:", ";color:", ";"], colorSet('background'), colorSet('color'))}

  ${props => props.variant === 'inverted' && css(["background:", ";color:", ";"], colorSet('backgroundSecondary'), colorSet('colorSecondary'))}
`;

export default StyledContainer;
