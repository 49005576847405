import styled$1, { css } from 'styled-components';
import { Input as Input$1, color, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { space } from '../../styles/spacing.js';

const Input = styled$1(Input$1)`
  input {
    display: block;
    width: 100%;
    background: ${color('neutral')};
    outline: none;
    padding: ${space(3)} ${space(3)} ${space(2)};
    border: none;

    ${props => props.error && css(["border:2px solid ", ";padding:", ";"], colorSet('accent'), space(2))}
  }

  input::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${space(2)};
  }

  .input__labelText,
  input {
    ${getTypography.copy}
  }

  .input__optional {
    margin-left: ${space(2)};
    ${getTypography.small}
  }

  .input__description,
  .input__error {
    display: block;
    ${getTypography.small}
  }

  .input__error {
    color: ${colorSet('accent')};
  }
`;

export default Input;
