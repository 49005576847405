import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { colorSet } from '../../styles/color';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div<{ size: 'small' | 'large' }>`
  display: block;
  position: relative;
  width: 40px;
  height: 40px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid ${colorSet('accent')};
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colorSet('accent')} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  ${props =>
    props.size === 'small' &&
    css`
      width: 20px;
      height: 20px;

      div {
        width: 16px;
        height: 16px;
        margin: 2px;
        border-width: 2px;
      }
    `}
`;

type SpinnerProps = {
  size?: 'small' | 'large';
  className?: string;
};

const Spinner = ({ size = 'small', className }: SpinnerProps) => (
  <StyledSpinner size={size} className={className}>
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
);

export default Spinner;
