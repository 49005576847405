import styled$1, { css } from 'styled-components';
import { colorSet } from '@ccg/core';

const StyledIcon = styled$1.div`
  display: inline;
  white-space: nowrap;
  vertical-align: middle;

  svg {
    fill: ${colorSet('accent')};

    /* &:hover {
      fill: ${colorSet('color')};
    } */

    ${({
  icon
}) => {
  switch (icon) {
    case 'external':
      return css([""]);

    case 'play':
      return css([""]);

    case 'facebook':
    case 'twitter':
    case 'instagram':
    case 'youtube':
    case 'linkedin':
    case 'xing':
      return css(["width:40px;height:40px;"]);

    default:
      return css([""]);
  }
}}
  }
`;

export default StyledIcon;
