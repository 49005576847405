import { useEffect, useRef, useState } from 'react';

const defaultThreshold: number[] = Array.from(Array(11).keys(), i => i / 10);

export interface UseIntersectProps {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number[];
}
const useIntersect = ({ root, rootMargin, threshold }: UseIntersectProps): [any, any, any] => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState<HTMLElement>();

  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if (observer && observer.current) observer.current.disconnect();
    observer.current = new window.IntersectionObserver(([item]) => updateEntry(item), {
      root: root || null,
      rootMargin,
      threshold: threshold || defaultThreshold
    });

    if (node) observer.current.observe(node);

    return () => {
      if (observer && observer.current) observer.current.disconnect();
    };
  }, [node]);

  return [setNode, entry, observer.current];
};

export default useIntersect;
