import { createGlobalStyle } from 'styled-components';
import { colorSet, zIndex } from '@ccg/core';
import { fontFamily } from './typography.js';

const GlobalStyle = createGlobalStyle`
  * { -webkit-tap-highlight-color: rgba(0,0,0,0);}

  html, body {
    width: 100%;
  }

  html {
    text-size-adjust: 100%;
    text-rendering: optimizeSpeed;
    font-size: 100%;
  }

  body {
    ${fontFamily()}

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    counter-reset: captions;
    background: ${colorSet('background')};
    color: ${colorSet('color')};
  }

  svg {
    fill: ${colorSet('color')};
  }
  
  input, textarea, select, button {
    ${fontFamily()}
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    image-rendering: crisp-edges;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight:normal;

    word-break: break-word;
    hyphens: auto;

    span {
      hyphens: inherit;
    }
  }
  
  html,
  body,
  div,
  article,
  section,
  main,
  footer,
  header,
  form,
  fieldset,
  legend,
  pre,
  code,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  textarea,
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  input[type='url'] {
    box-sizing: border-box;
  }

  figure {
    margin: 0;
  }

  #__next {
    overflow: hidden;
    min-height: 100vh;
  }

  main,
  #__next, #root {
    width: 100%;
  }

  main {
    display: block;
    position: relative;
  }

  p {
    margin: 0;
  }
  
  i {
    font-style: italic;
  }

  b, strong {
    font-weight: 700;
  }

  *::selection {
    color: ${colorSet('color')};
    background: ${colorSet('accent')};
  }

  @media print {
    @page { margin: 2cm }

    *,
    *:before,
    *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: ' (' attr(href) ')';
    }

    abbr[title]:after {
      content: ' (' attr(title) ')';
    }
    /*
    * Don't show links that are fragment identifiers
    */
    
    a[href^='#']:after,
    a[href^='javascript:']:after {
      content: '';
    }

    pre {
      white-space: pre-wrap !important;
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /*
    * Printing Tables:
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */
    
    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${colorSet('accent')};
    position: fixed;
    z-index: ${zIndex('overlay')};
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
  }

  /*** iPhone and iOS Form Input Zoom Fixes https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/ ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  .sbdocs-preview {
    background: ${colorSet('background')};
    color: ${colorSet('color')};
  }

  .sbdocs-table {
    color: #333;
  }

  .sbdocs-h1,.sbdocs-h2,.sbdocs-h3,.sbdocs-h4,.sbdocs-h5,.sbdocs-h6,.sbdocs-p,ul,.sbdocs-ol {
    max-width: 800px;
  }

  .sbdocs-content {
    max-width: none;
  }

  .transition__curtain {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    display: none;
    background: ${colorSet('accent')};
    pointer-events: none;
  }

  .transition-enter {
    .transition__content {
      opacity: 1;
      transform: translateX(50%);
    }

    .transition__curtain {
      display: block;
      transform: translateX(0);
    }
  }

  .transition-enter-active {
    .transition__content {
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.7s ease-out;
    }

    .transition__curtain {
      display: block;
      transform: translateX(-100%) rotate(0) scale(1);
      transition: transform 0.4s ease-out;
    }
  }

  .transition-enter-done {
    .transition__content {
      opacity: 1;
    }

    .transition__curtain {
      display: block;
      opacity: 0;
      transform: translateX(100%) rotate(2deg) scale(1.2);
    }
  }

  .transition-exit {
    .transition__content {
      opacity: 1;
      transform: translateX(0);
    }

    .transition__curtain {
      display: block;
      transform: translateX(120%) rotate(2deg) scale(1.2);
    }
  }

  .transition-exit-active {
    .transition__content {
      opacity: 1;
      transform: translateX(-50%);
      transition: transform 0.7s ease-out;
    }

    .transition__curtain {
      display: block;
      transform: translateX(0);
      transition: transform 0.4s ease-out;
    }
  }
`;

export default GlobalStyle;
