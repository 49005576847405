import styled$1, { css } from 'styled-components';
import { getProperty, space, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

const StyledTeaser = styled$1.div`
  ${getTransition}
  ${getProperty('grid-column', {
  default: ' 1 / span 6'
})};

  &:not(:first-child) {
    margin-top: ${space(25)};
  }

  ${({
  hasMedia,
  isEven
}) => {
  let gridColumnCSS = '';

  if (!hasMedia && isEven) {
    gridColumnCSS = getProperty('grid-column', {
      m: '7 / span 6'
    });
  } else if (!hasMedia && !isEven) {
    gridColumnCSS = getProperty('grid-column', {
      m: '1 / span 6'
    });
  } else {
    gridColumnCSS = getProperty('grid-column', {
      m: '1 / span 12'
    });
  }

  return css(["", ""], gridColumnCSS);
}}
  .teaser__link,
  .teaser__tags {
    ${({
  hasMedia
}) => hasMedia ? css(["", ";"], getGrid({
  default: 6,
  m: 12
}, false)) : css(["", ";"], getGrid({
  default: 6
}, false))};
  }

  .teaser__media {
    margin-bottom: ${space(5)};
    grid-column: 1 / span 6;

    img,
    video {
      display: block;
      width: 100%;
      overflow: hidden;
      transition: transform 0.2s;
    }

    ${mq('m')} {
      grid-row: 1;
      margin-bottom: 0;

      /* spread columns */
      ${({
  featured,
  isEven
}) => {
  let gridColumnCSS = '';

  if (featured && isEven) {
    gridColumnCSS = 'grid-column: 1 / span 6';
  } else if (featured && !isEven) {
    gridColumnCSS = 'grid-column: 7 / span 6';
  } else if (isEven) {
    gridColumnCSS = 'grid-column: 1 / span 6';
  } else if (!isEven) {
    gridColumnCSS = 'grid-column: 7 / span 6';
  }

  return css(["", ""], gridColumnCSS);
}}
    }

    ${mq('l')} {
      /* spread columns */
      ${({
  featured,
  isEven
}) => {
  let gridColumnCSS = '';

  if (featured && isEven) {
    gridColumnCSS = 'grid-column: 1 / span 8';
  } else if (featured && !isEven) {
    gridColumnCSS = 'grid-column: 5 / span 8';
  } else if (isEven) {
    gridColumnCSS = 'grid-column: 1 / span 6';
  } else if (!isEven) {
    gridColumnCSS = 'grid-column: 7 / span 6';
  }

  return css(["", ""], gridColumnCSS);
}}
    }
  }

  .teaser__content,
  .teaser__tags > div {
    align-self: center;
    grid-column: 1 / span 6;

    ${mq('m')} {
      grid-row: 1;
      /* spread columns */
      ${({
  featured,
  isEven,
  hasMedia
}) => {
  let gridColumnCSS = '';

  if (!hasMedia) {
    gridColumnCSS = 'grid-column: 1 / span 4';
  } else if (featured && isEven) {
    gridColumnCSS = 'grid-column: 7 / span 6';
  } else if (featured && !isEven) {
    gridColumnCSS = 'grid-column: 1 / span 6';
  } else if (isEven) {
    gridColumnCSS = 'grid-column: 7 / span 6';
  } else if (!isEven) {
    gridColumnCSS = 'grid-column: 1 / span 6';
  }

  return css(["", ""], gridColumnCSS);
}}
    }

    ${mq('l')} {
      /* spread columns */
      ${({
  featured,
  isEven,
  hasMedia
}) => {
  let gridColumnCSS = '';

  if (!hasMedia) {
    gridColumnCSS = 'grid-column: 1 / span 4';
  } else if (featured && isEven) {
    gridColumnCSS = 'grid-column: 9 / span 4';
  } else if (featured && !isEven) {
    gridColumnCSS = 'grid-column: 1 / span 4';
  } else if (isEven) {
    gridColumnCSS = 'grid-column: 7 / span 4';
  } else if (!isEven) {
    gridColumnCSS = 'grid-column: 3 / span 4';
  }

  return css(["", ""], gridColumnCSS);
}}
    }
  }

  .teaser__link {
    ${mq('m')} {
      margin-bottom: ${space(-20)};
    }
  }

  .teaser__content {
    ${mq('m')} {
      margin-bottom: ${space(25)};
    }
  }

  .teaser__topic {
    ${getTypography.small};
    margin-bottom: 2px;
  }

  .teaser__phoneNumber,
  .teaser__email {
    margin-top: ${space(4)};
  }

  .teaser__phoneNumber + .teaser__email {
    margin-top: 0;
  }

  .teaser__logo {
    margin-bottom: ${space(2)};
    height: ${props => props.logoHorizontal ? space(5) : 'auto'};
    width: ${props => props.logoHorizontal ? 'auto' : space(14)};
    display: block;
  }

  .teaser__headline {
    position: relative;
    margin-bottom: ${space(4)};
    color: ${colorSet('accent')};
    display: inline-block;
    word-break: break-word;
    transition: transform 0.4s;
    will-change: transform;

    ${mq('m')} {
      width: 127%;
      margin-left: ${({
  isEven,
  hasMedia
}) => isEven && hasMedia && '-27%'};
      z-index: 1;
    }
  }

  .teaser__tags {
    margin-top: ${space(4)};

    ${mq('m')} {
      margin-top: 0;
    }
  }

  .teaser__link:hover {
    .teaser__media img {
      transform: scale(1.05);
    }

    .teaser__headline {
      transform: scale(1.02);
    }

    .teaser__cta span:after {
      transform: translateY(${space(-0.5)});
    }
  }
`;

export default StyledTeaser;
