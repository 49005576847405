/* eslint-disable no-irregular-whitespace */
import styled from 'styled-components';

interface StyledSwitchProps {
  readonly active: boolean;
}

const StyledSwitch = styled.div<StyledSwitchProps>`
  display: flex;
  align-items: center;
  margin: 1.5em 0;
  width: 100%;

  .switch__text  {
    flex-grow: 2;
    padding-right: 20px;

    .switch__label {
      margin: 0;
      display: flex;
      font-size: 14px;
      align-items: center;
    }

    .switch__description {
      margin: 0;
      margin-top: 10px;
    }
  }

  button {
    position: relative;
    outline: none;
    margin: 0;
    padding: 0;
    appearance: none;
    border: 1px solid black;
    width: 60px;
    height: 30px;
    border-radius: 30px;
    cursor: pointer;
    background: transparent;

    span {
      position: absolute;
      top: 4px;
      left: 4px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: black;
      transform: translateX(${props => (props.active ? '30px' : '0')});
      will-change: transform;
      transition: transform 0.2s ease-in-out;

      svg {
        fill: #fff;
        stroke: #fff;
        fill-opacity: 0;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }
`;

export default StyledSwitch;
