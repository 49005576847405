import React, { useState, useEffect } from 'react';

interface ScrollContextProps {
  scrollDirection: string;
  scrollY: number;
  scrollYPercentage: number;
}

const defaultScroll = {
  scrollDirection: 'down',
  scrollY: 0,
  scrollYPercentage: 0
};

export const ScrollContext = React.createContext<ScrollContextProps>(defaultScroll);

const ScrollProvider = ({ children }) => {
  const [scrollY, setScrollY] = useState<number>(0);
  const [scrollYPercentage, setScrollYPercentage] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<string>('down');

  let scrollPosition = 0;

  useEffect(() => {
    const handleScroll = () => {
      const percentage =
        window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);

      setScrollYPercentage(percentage);
      setScrollY(window.scrollY);

      if (window.scrollY > scrollPosition) {
        setScrollDirection('down');
      } else if (window.scrollY < scrollPosition) {
        setScrollDirection('up');
      }

      scrollPosition = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollContext.Provider value={{ scrollDirection, scrollYPercentage, scrollY }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const ScrollConsumer = ScrollContext.Consumer;

export default ScrollProvider;
