import styled from 'styled-components';

const StyledVideo = styled.div`
  .video__wrapper,
  .video__play,
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default StyledVideo;
