import { useEffect, useState } from 'react';
import useIntersect from './useIntersect';

const useReveal = (): [any, boolean] => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, entry, observer] = useIntersect({});

  const isIntersecting = entry?.isIntersecting;

  useEffect(() => {
    if (isIntersecting) {
      setIsVisible(true);
      if (observer) {
        observer.disconnect();
      }
    }
  }, [isIntersecting]);

  return [ref, isVisible];
};

export default useReveal;
