/* eslint-disable import/prefer-default-export */

/**
 * Returns color from theme referenced by id
 * @param  {string} id - Identifier for the color in the configuration map. Default: white
 * @returns {string} Resulting color from configuration file
 * @example color: ${color('white')};
 */
export const color = (id: string) => ({ theme }: any) => {
  if (!theme) {
    throw new Error('No theme object provided');
  }

  const { colors } = theme;

  if (!colors) {
    throw new Error('Colors are not defined in theme');
  }

  if (!Object.prototype.hasOwnProperty.call(colors, id)) {
    throw new Error(`colors ${id} not known`);
  }

  return colors[id];
};

/**
 * Returns color from theme colorSet object in theme based on contentType and id
 * @param  {string} id - Identifier for the color in the colorSet object
 * @returns {string} Resulting color from colorSet object
 * @example background: ${colorSet('background')};
 */
export const colorSet = (id: string) => ({ theme }: any) => {
  if (!theme) {
    throw new Error('No theme object provided');
  }

  const { colorSets, contentType } = theme;

  if (!colorSets) {
    throw new Error('colorSet object is not defined in theme');
  }

  if (!Object.prototype.hasOwnProperty.call(colorSets, contentType)) {
    return colorSets.neutral[id];
  }

  if (!Object.prototype.hasOwnProperty.call(colorSets[contentType], id)) {
    throw Error(`${id} not in colorSets.${contentType} object`);
  }

  return colorSets[contentType][id];
};
