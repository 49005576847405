import React, { useEffect, useState, FC } from 'react';

import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';

import { getYoutubeId, getVimeoId } from './helper';

import AspectRatio from '../AspectRatio/AspectRatio';
import Image from '../Image/Image';

import StyledVideo from './Video.style';

export interface VideoProps {
  url: string;
  onReady?: () => void;
  autoplay?: boolean;
  className?: string;
}

const Video: FC<VideoProps> = ({ url, onReady, autoplay, className }) => {
  const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
  const isVimeo = url.includes('vimeo.com');

  return (
    <StyledVideo className={className}>
      <AspectRatio ratio="16:9">
        {isYouTube && (
          <YouTube
            videoId={getYoutubeId(url)}
            opts={{ height: '', width: '', playerVars: { autoplay: autoplay ? 1 : 0 } }}
            onReady={() => {
              if (onReady) onReady();
            }}
          />
        )}
        {isVimeo && (
          <Vimeo
            video={url}
            showPortrait
            autoplay={autoplay}
            onReady={() => {
              if (onReady) onReady();
            }}
          />
        )}
      </AspectRatio>
    </StyledVideo>
  );
};

Video.defaultProps = {
  autoplay: false
};

export default Video;

export interface VideoPosterProps {
  url: string;
  className?: string;
}

export const VideoPoster: FC<VideoPosterProps> = ({ url, className }) => {
  const [vimeoFallbackImage, setVimeoFallbackImage] = useState('');

  const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
  const isVimeo = url.includes('vimeo.com');

  useEffect(() => {
    async function getVimeoPlaceholder() {
      if (isVimeo) {
        const videoId = getVimeoId(url);
        const data = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
        const json = await data.json();
        // eslint-disable-next-line dot-notation
        setVimeoFallbackImage(json[0]['thumbnail_medium']);
      }
    }
    getVimeoPlaceholder();
  }, []);

  return (
    <AspectRatio ratio="16:9" className={className}>
      {isYouTube && (
        <Image
          src={`https://img.youtube.com/vi/${getYoutubeId(url)}/maxresdefault.jpg`}
          alt={`preview-image-${getYoutubeId(url)}`}
          lazy
        />
      )}
      {isVimeo && vimeoFallbackImage && (
        <Image src={vimeoFallbackImage} alt={`preview-image-${getVimeoId(url)}`} />
      )}
    </AspectRatio>
  );
};
