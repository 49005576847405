import styled$1, { css } from 'styled-components';
import { Button, colorSet, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledButton = styled$1(Button)`
  margin: 0 auto;
  display: block;
  color: inherit;
  ${getTypography.copy};
  line-height: 1;
  letter-spacing: 2px;
  transition: padding 100ms, background-color 100ms;

  .button__label {
    transition: color 100ms;

    & > div {
      display: inline-flex;
      align-items: center;
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }

  ${props => !props.noHover && css(["&:hover{.button__label{color:", ";}}"], colorSet('accent'))}

  ${props => {
  switch (props.variant) {
    case 'primary':
      return css(["color:", ";padding:", " ", ";border:2px solid ", ";", ""], colorSet('accent'), space(3), space(6), colorSet('accent'), !props.noHover && css(["&:hover{background:", ";.button__label{color:", ";svg{fill:", ";}}}"], colorSet('accent'), colorSet('background'), colorSet('background')));

    case 'secondary':
      return css(["color:", ";padding-bottom:", ";border-bottom:2px solid ", ";", ""], colorSet('color'), space(1), colorSet('accent'), !props.noHover && css(["&:hover{padding-bottom:", ";.button__label{color:", ";}}"], space(0.5), colorSet('accent')));

    default:
      return css([""]);
  }
}}
`;

export default StyledButton;
