import styled$1 from 'styled-components';
import { zIndex, colorSet, mq, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getOuterGridContainer, getInnerGridContainer, getOuterMargins } from '../Grid/Grid.js';

const StyledCookieLayer = styled$1.div`
  position: fixed !important;
  ${getOuterGridContainer()};
  z-index: ${zIndex('modal')};
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${colorSet('backgroundSecondary')};

  .cookieLayer__content {
    ${getInnerGridContainer()};
    position: relative;
  }

  .cookieLayer__inner {
    ${getOuterMargins()};

    ${mq('m')} {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      justify-content: space-between;
    }
  }

  .cookieLayer__text {
    position: relative;
    color: ${colorSet('colorSecondary')};
    max-width: 656px;
    flex: 1 0 50%;
    margin: ${space(4)} ${space(4)} ${space(4)} 0;

    p {
      ${getTypography.small};
      line-height: 2.2;
    }
  }

  .cookieLayer__actions {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: ${space(4)} 0;
  }

  .cookieLayer__button {
    margin-top: ${space(4)};
    margin-left: 0;
    text-align: left;

    &:first-child {
      margin-right: ${space(10)};

      ${mq('m')} {
        margin-left: auto;
      }
    }

    &:nth-child(2) {
      margin-top: ${space(5)};
      margin-left: 0;
      padding-top: 2px;
      color: ${colorSet('colorSecondary')};

      ${mq('m')} {
        margin-top: ${space(1)};
      }
    }

    ${mq('m')} {
      margin: 0 ${space(5)};
    }
  }
`;

export default StyledCookieLayer;
