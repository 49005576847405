import styled$1, { css } from 'styled-components';
import { space, getProperty, zIndex, colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';

/* eslint-disable no-irregular-whitespace */
const StyledQuote = styled$1.div`
  position: relative;
  margin: ${space(10)} 0;
  padding: 0;

  .quote__author,
  .quote__content {
    ${({
  isHero,
  hasPhoto
}) => isHero ? css(["", ";"], getProperty('grid-column', hasPhoto ? {
  default: '1 / span 6',
  m: '3 / span 9'
} : {
  default: '1 / span 6',
  m: '2 / span 10'
})) : css(["", ";"], getTextColumns)}
  }

  cite {
    display: block;
    font-style: normal;
    grid-row: 2;
    align-self: center;
    position: relative;
    z-index: ${zIndex('base')};
  }

  .quote__content {
    color: ${colorSet('accent')};
    hyphens: auto;
    margin-bottom: ${space(6)};
    align-self: flex-end;

    ${mq('m')} {
      margin-top: ${space(10)};
    }
  }

  .quote__icon {
    line-height: 0.76;
    font-size: 100px;
    margin-bottom: ${space(-8)};

    ${mq('m')} {
      position: absolute;
      top: 5px;
      font-size: 150px;
      line-height: 0.51;
    }
  }

  .quote__author-image-wrapper  {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }

  .quote__author-image {
    background: ${colorSet('background')};
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: ${space(5)};

    ${mq('m')} {
      margin-bottom: 0;
      margin-top: ${space(8)};
    }

    img {
      filter: grayscale(1);
      opacity: 0.8;
    }
  }

  ${({
  isHero
}) => isHero && css([".quote__wrapper{padding:", " 0;background:", ";}.quote__author,.quote__content{color:", ";}.quote__text{", "{", ";}}"], space(10), colorSet('backgroundSecondary'), colorSet('colorSecondary'), mq('m'), getTypography.quoteBig)}
`;

export default StyledQuote;
