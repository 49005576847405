import styled$1, { css } from 'styled-components';
import { Link, colorSet, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledLink = styled$1(Link)`
  position: relative;
  text-transform: ${({
  transform
}) => transform};
  ${({
  variant
}) => getTypography[variant]}
  transition: color 100ms, padding 100ms, background-color 100ms;

  svg {
    transition: fill 100ms, bottom 100ms;
  }

  ${props => !props.noHover && css(["&:hover{color:", ";}"], colorSet('accent'))}


  ${props => props.type === 'primary' && css(["color:", ";padding:", " ", ";border:2px solid ", ";letter-spacing:2px;", ""], colorSet('accent'), space(2.5), space(7), colorSet('accent'), !props.noHover && css(["&:hover{background:", ";color:", ";svg{fill:", ";}}"], colorSet('accent'), colorSet('background'), colorSet('background')))}

  ${props => props.type === 'secondary' && css(["padding-bottom:", ";border-bottom:2px solid ", ";letter-spacing:2px;", ""], space(0.5), colorSet('accent'), !props.noHover && css(["&:hover{padding-bottom:", ";color:", ";}"], space(0.25), colorSet('accent')))}

  /* external svg */
  ${props => props.icon === 'external' && css(["svg{position:relative;bottom:-5px;left:2px;transition:transform 100ms;}&:hover svg{transform:translateY(-1px);}"])}

  /* icon hover */
  ${props => !props.noHover && props.icon && !props.type && css(["div{transition:margin-left 100ms;}&:hover{div{margin-left:2px;}}"])}

`;

export default StyledLink;
