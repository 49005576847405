import { px2rem } from '@ccg/core';
import { step } from '@4rk/modular-system';

const space = (multiplier = 0, usePx = false) => props => {
  if (!props.theme) {
    throw new Error('No theme object provided');
  }

  const base = step('size', 'spacingBase')(props);
  const spacingValue = parseFloat(base) * parseFloat(multiplier);
  return usePx ? `${spacingValue}px` : px2rem(spacingValue);
};

export { space };
