import styled$1 from 'styled-components';
import { colorSet, zIndex, space, getProperty } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import Grid$1 from '../Grid/Grid.js';

const StyledTextTeasers = styled$1(Grid$1)`
  position: relative;
  background: ${colorSet('background')};
  z-index: ${zIndex('middle')};
  grid-row-gap: ${space(17)};
  padding: ${space(12)} 0 ${space(20)};

  > * {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: 'span 6',
  l: 'span 4'
})};
  }

  .textTeasers__headline {
    color: ${colorSet('accent')};
  }

  .textTeasers__link {
    display: flex;
    flex-direction: column;
    gap: ${space(5)};

    h3 {
      padding-right: ${space(18)};
      transition: transform 0.4s;
    }
  }

  .textTeasers__cta span {
    position: relative;
    display: inline-block;
    ${getTypography.copy};
    letter-spacing: 2px;
    padding-bottom: ${space(1)};

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: ${colorSet('accent')};
      transition: transform 0.1s ease-in-out;
    }
  }

  .textTeasers__link:hover {
    h3 {
      transform: scale(1.02);
    }

    p {
      color: ${colorSet('color')};
    }

    .textTeasers__cta span:after {
      transform: translateY(${space(-0.5)});
    }
  }
`;

export default StyledTextTeasers;
