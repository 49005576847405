import styled$1 from 'styled-components';
import { space, color, mq, fontFamily } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid, getOuterMargins } from '../Grid/Grid.js';

const StyledCategoryMarquee = styled$1.div`
  margin: ${space(30)} 0;
  background: ${color('light')};


  .categoryMarquee__headline {
    ${getGrid()}
    position: relative;
    text-align: center;
    margin: ${space(10)} 0;
    color: ${color('highlight01')};
    ${getTypography.intro}
    ${getOuterMargins()}

    ${mq('m')} {
      ${getTypography.headline}
    }

    & > div {
      grid-column: 1 / span 12;
    }

    a {
      ${fontFamily('accent')};
      color: ${color('bright')};
    }

    p,
    a {
      word-break: break-word;
      hyphens: auto;

      ${mq('m')} {
        word-break: keep-all;
      }
    }
  }

  .categoryMarquee__marquee {
    width: 100vw;
    margin: ${space(4)} 0;

    ${mq('xl')} {
      transform: translateX(calc((100vw - 1440px) / -2));
    }

    a {
      color: ${color('highlight01')};
      padding: 0 ${space(8)};
      line-height: 1;

      &:hover {
        color: ${color('bright')};
      }
    }
  }

  .categoryMarquee__logo-wrapper {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 85px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 85%;
      max-height: 85%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default StyledCategoryMarquee;
