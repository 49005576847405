import styled$1 from 'styled-components';
import { colorSet, space } from '@ccg/core';

const StyledArticleVideo = styled$1.div`
  width: 100%;
  position: relative;

  .video__play-button {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .video__poster {
    background: ${colorSet('background')};
    position: relative;

    img {
      opacity: 0.8;
    }
  }

  .video__play-overlay {
    height: 100%;
    position: absolute;
    left: ${space(6)};
    top: ${space(6)};
    pointer-events: none;

    svg {
      transition: transform 300ms ease-in-out;
    }
  }

  .video__button-label {
    display: flex;
    align-items: center;

    svg {
      margin-left: ${space(2)};
    }
  }

  .video__play-button:hover + .video__play-overlay svg {
    transform: scale(0.95);
  }
`;

export default StyledArticleVideo;
