import React, { FC } from 'react';

import { StyledButton } from './Button.style';

interface ButtonProps {
  disabled?: boolean;
  className?: string;
}

const Button: FC<ButtonProps> = ({ children, disabled, className, ...props }) => (
  <StyledButton
    type="button"
    disabled={disabled}
    className={className}
    {...props}
  >
    {children}
  </StyledButton>
);

export default Button;
