/* eslint-disable react/no-array-index-key */
// import React, { useState, useEffect, ReactNode } from 'react';
// import Ticker from 'react-ticker';

// import useIntersect from '../../hooks/useIntersect';

// import StyledMarquee from './Marquee.style';

// export type MarqueeProps = {
//   children: ReactNode;
//   direction?: 'toRight' | 'toLeft';
//   speed?: number;
//   move?: boolean;
//   offset?: string | number;
//   fallbackOffset?: string;
//   className?: string;
// };

// interface Document {
//   documentMode?: any;
// }

// const Marquee = ({
//   children,
//   direction = 'toLeft',
//   speed = 5,
//   move = true,
//   offset,
//   fallbackOffset = '0%',
//   className
// }: MarqueeProps) => {
//   const [isMoving, setIsMoving] = useState(true);
//   const [ref, entry, observer] = useIntersect({});

//   const [IE11, setIE11] = useState(false);

//   useEffect(() => {
//     setIE11(!!window.MSInputMethodContext && !!(document as Document).documentMode);

//     return () => {
//       if (observer && observer.disconnect) {
//         observer.disconnect();
//       }
//     };
//   }, []);

//   const handleMove = bool => {
//     if (IE11) return;
//     if (isMoving === bool) return;
//     setIsMoving(bool);
//   };

//   useEffect(() => {
//     const isIntersecting: boolean = entry?.isIntersecting || false;
//     handleMove(isIntersecting);
//   }, [entry]);

//   return (
//     <StyledMarquee
//       className={className}
//       ref={ref}
//       onMouseEnter={() => handleMove(false)}
//       onMouseLeave={() => handleMove(true)}
//       onTouchStart={() => handleMove(false)}
//       onTouchCancel={() => handleMove(true)}
//       fallbackOffset={fallbackOffset}
//     >
//       {IE11 ? (
//         <div className="marquee__element marquee__ie11">{children}</div>
//       ) : (
//         <Ticker direction={direction} offset={offset} move={move && isMoving} speed={speed}>
//           {() => <div className="marquee__element">{children}</div>}
//         </Ticker>
//       )}
//     </StyledMarquee>
//   );
// };

// export default Marquee;

import React, { ReactNode, useState } from 'react';
import styled, { keyframes } from 'styled-components';

type MarqueeProps = {
  children: ReactNode;
  className?: string;
  inverted?: boolean;
  seconds?: number;
};

const animation = keyframes`
  from { transform: translate3d(0, 0, 0); }

	to { transform: translate3d(-50%, 0, 0); }
`;

const animationInverted = keyframes`
  from { transform: translate3d(-50%, 0, 0); }

  to { transform: translate3d(0, 0, 0); }
`;

const StyledMarquee = styled.div<MarqueeProps & { isMoving: boolean }>`
  width: 100vw;
  overflow: hidden;
  overflow-y: visible;

  .marquee__inner {
    width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    will-change: transform;
    animation: ${props => (props.inverted ? animationInverted : animation)}
      ${props => props.seconds}s linear infinite;

    animation-play-state: ${props => (props.isMoving ? 'running' : 'paused')};
  }

  .marquee__child {
    white-space: nowrap;
  }
`;

const Marquee = ({ children, inverted, seconds = 60, className }: MarqueeProps) => {
  const [isMoving, setIsMoving] = useState(true);

  const handleMove = bool => {
    if (isMoving === bool) return;
    setIsMoving(bool);
  };

  return (
    <StyledMarquee
      className={className}
      inverted={inverted}
      seconds={seconds}
      isMoving={isMoving}
      onMouseEnter={() => handleMove(false)}
      onMouseLeave={() => handleMove(true)}
      onTouchStart={() => handleMove(false)}
      onTouchCancel={() => handleMove(true)}
    >
      <div className="marquee__inner" aria-hidden="true">
        {[...Array(10)].map(() =>
          React.Children.map(children, (i, index) => (
            <div className="marquee__child" key={index}>
              {i}
            </div>
          ))
        )}
      </div>
    </StyledMarquee>
  );
};

export default Marquee;
