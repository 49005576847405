import styled$1 from 'styled-components';
import { space, colorSet } from '@ccg/core';

/* eslint-disable no-irregular-whitespace */
const StyledCaption = styled$1.div`
  margin: ${space(4)} 0;

  .caption__caption {
    p {
      color: ${colorSet('accent')};
      margin: 0;
    }
  }

  .caption__credits {
    margin-top: ${space(2)};
  }
`;

export default StyledCaption;
