import React from 'react';

import StyledHoneypot from './Honeypot.style';

type HoneypotProps = {
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

// Honeypot input to protect your Forms against Spammers
const Honeypot = ({ id, onChange, placeholder }: HoneypotProps) => (
  <StyledHoneypot>
    {/* hide it from screen-readers with tabindex and aria-hidden attributes */}
    {/* and hide it visually via css */}
    <label htmlFor={id} aria-hidden="true">
      Bitte leer lassen
      <input
        id={id}
        name={id}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChange}
        tabIndex={-1}
      />
    </label>
  </StyledHoneypot>
);

export default Honeypot;
