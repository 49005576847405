import React, { FC } from 'react';

export type RadioOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

export interface RadioProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  id: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
  options: RadioOption[];
}

const Radio: FC<RadioProps> = ({
  className,
  disabled,
  error,
  errorMessage,
  id,
  label,
  onChange,
  required,
  value,
  options
}) => (
  <label htmlFor={id} className={className}>
    <p className="radio__label">
      {label}
      {!required && <span className="radio__optional">optional</span>}
    </p>
    <div className="radio__options">
      {options.map(option => (
        <div className="radio__item" key={option.value}>
          <input
            id={option.value}
            value={option.value}
            name={id}
            type="radio"
            onChange={onChange}
            disabled={disabled || option.disabled}
            required={required}
            checked={value === option.value}
          />
          <label htmlFor={option.value}>
            <div className="radio__custom-icon">
              <div className="radio__custom-icon-inner" />
            </div>
            <span>{option.label}</span>
          </label>
        </div>
      ))}
    </div>

    {error && errorMessage && <span className="radio__error">{errorMessage}</span>}
  </label>
);

export default Radio;
