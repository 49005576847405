/* eslint-disable indent */
import React, { useState, useEffect, FC, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import useWindowEvent from '../hooks/useWindowEvent';

import { getProperty } from './mediaQuery';

interface DebugGridProps {
  isHidden?: boolean;
  onUpdate?: (hidden: boolean) => void;
  className?: string;
}
/**
 * Displays a simple debug Grid to show columns and gaps
 * @param {bool} isHidden - Boolean for visibility state. Default: true
 * @returns {string} Returns simple debug grid (just use in development)
 * @example <DebugGrid />;
 */
export const DebugGrid: FC<DebugGridProps> = ({ isHidden = true, onUpdate, className }) => {
  const { grid } = useContext(ThemeContext);
  const [hidden, setHidden] = useState(isHidden);

  useEffect(() => {
    setHidden(isHidden);
  }, [isHidden]);

  useEffect(() => {
    if (onUpdate) onUpdate(hidden);
  }, [hidden]);

  const createColumns = () => {
    const columns: JSX.Element[] = [];
    for (let i = 0; i < grid.columns.m; i += 1) {
      columns.push(<div key={i} className="debugGrid__column" />);
    }
    return columns;
  };

  const handleKeyDown = event => {
    if (event.code === 'KeyG') {
      setHidden(prev => !prev);
    }
  };

  useWindowEvent('keydown', e => handleKeyDown(e));

  return (
    <DebugGridContainer className={className} hidden={hidden} columns={grid.columns}>
      {createColumns()}
    </DebugGridContainer>
  );
};

interface DebugGridContainerProps {
  columns: {
    default: string | number;
    [key: string]: string | number;
  };
}

const DebugGridContainer = styled.div<DebugGridContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid !important;
  pointer-events: none;

  ${p => getProperty('grid-template-columns', p.columns, i => `repeat(${i}, 1fr)`)(p)}

  ${props =>
    props.hidden &&
    css`
      display: none !important;
    `}
  .debugGrid__column {
    position: relative;
    opacity: 0.5;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    outline: 1px solid #ff0000;
  }
`;
