import React, { ReactNode, FC } from 'react';

export type SelectOption = {
  id: string;
  label: string;
  value: string;
};

export interface SelectProps {
  className?: string;
  disabled?: boolean;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  icon: ReactNode;
  id: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (val: string) => void;
  onFocus?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  options: SelectOption[];
}

const Select: FC<SelectProps> = ({
  className,
  defaultValue,
  disabled,
  description,
  error,
  errorMessage,
  icon,
  id,
  label,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required
}) => (
  <div className={className}>
    <label htmlFor={id}>
      {label}
      {!required && <span className="select__optional">optional</span>}
      {description && <span className="select__description">{description}</span>}
    </label>

    <div className="select__wrapper">
      <select
        id={id}
        name={id}
        onBlur={e => {
          if (onBlur) return onBlur(e.target.value);
        }}
        onFocus={onFocus}
        onChange={onChange}
        aria-invalid={error}
        aria-required={required}
        required={required}
        disabled={disabled}
        defaultValue={placeholder ? '' : defaultValue}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(option => (
          <option value={option.value} key={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="select__custom-icon">{icon}</div>
    </div>

    {error && errorMessage && <span className="select__error">{errorMessage}</span>}
  </div>
);

export default Select;
