import styled$1 from 'styled-components';
import { zIndex, mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../../../styles/typography.js';
import { space } from '../../../../styles/spacing.js';

const StyledOverview = styled$1.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  height: 100vh;

  .overview__images {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 0 ${space(2)};
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: ${zIndex('base')};
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ${mq('m')} {
      padding: 0 ${space(24)};
    }
  }

  .overview__row {
    display: flex;
  }

  .overview__image {
    width: 33.33%;
    max-width: 300px;
    flex-shrink: 0;
    margin: ${space(6)} 0;
    margin-right: ${space(10)};

    ${mq('m')} {
      margin-right: ${space(12)};
    }

    img {
      display: block;
      width: 100%;
      max-width: 300px;
      max-height: 35vh;
      height: 100%;
      object-fit: contain;
    }
  }

  .overview__image-trigger {
    width: 100%;
    align-self: start;
  }
`;

export default StyledOverview;
